import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListHistoryCalls } from "../../../Types/account/account.type";
import { MainUser } from "../../../Types/user.type";

type State = {
    entities: MainUser,
    reload: boolean
};

const initialState: State = {
    entities: {
        name: "",
        company_name: "",
        list_contact: [],
        list_service: [],
        tel_num: "",
        balance: "",
        utc: "",
        utc_name: "",
    },
    reload: false
};

const mainUserSlice = createSlice({
    name: "mainUser",
    initialState,
    reducers: {
        mainUserReceved: (state, action: PayloadAction<MainUser>) => {
            state.entities = action.payload;
        },
        reloadReceved: (state) => {
            state.reload = !state.reload
        }
    }
});


const { reducer: mainUserReducer, actions } = mainUserSlice;
export const { mainUserReceved, reloadReceved } = actions;

export default mainUserReducer;