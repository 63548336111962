import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import stepReducer from "./auth/step";
import rootSaga from "../Saga/rootSaga";
import userReducer from "./auth/user";
import timeZonesReducer from "./timeZones/timeZones";
import servicesReducer from "./services/services";
import historyCallsReducer from "./historyCalls/historyCalls";
import historyBalanceReducer from "./historyBalance/historyBalance";
import mainUserReducer from "./mainUser/mainUser";
import innUserReducer from "./innUser/innUser";
import requisiteUserReducer from "./mainUser/requisite";
import storage from 'redux-persist/lib/storage'
import isLoggedInReducer from "./auth/isLoggedIn";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from "redux-persist";
import errorsMessageReducer from "./errorsMessage/errorsMessage";
import statisticOutCallReducer from "./statisticOutCall/statisticOutCall";

const rootReducer = combineReducers({
    user: userReducer,
    step: stepReducer,
    timeZonesDefaults: timeZonesReducer,
    services: servicesReducer,
    historyCalls: historyCallsReducer,
    historyBalance: historyBalanceReducer,
    mainUser: mainUserReducer,
    innUser: innUserReducer,
    requisiteUser: requisiteUserReducer,
    isLoggedIn: isLoggedInReducer,
    errorsMessage: errorsMessageReducer,
    statisticOutCall: statisticOutCallReducer
});

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ["isLoggedIn"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            ignoredActionPaths: ["payload.navigate", "payload.setActive"]
        }
    }).concat(sagaMiddleware)
});

sagaMiddleware.run(rootSaga)

export const persistor = persistStore(store);
export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;