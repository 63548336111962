// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tableHistoryBalance_container__bg9\\+z {\n  min-height: 650px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.tableHistoryBalance_table__pKcv0 {\n  margin: 40px 0 20px 0;\n  width: 100%;\n  border-collapse: collapse;\n}\n.tableHistoryBalance_table__pKcv0 thead {\n  border-bottom: 2px solid #706F8E;\n}\n.tableHistoryBalance_table__pKcv0 th {\n  padding-bottom: 15px;\n  font-size: 18px;\n  text-align: center;\n  color: #706F8E;\n}\n.tableHistoryBalance_table__pKcv0 td {\n  text-align: center;\n  font-size: 18px;\n  color: #706F8E;\n  padding: 15px 0;\n}\n.tableHistoryBalance_table__pKcv0 .tableHistoryBalance_first_item__0al1C {\n  text-align: start;\n  width: 10%;\n}", "",{"version":3,"sources":["webpack://./src/Components/Ui/Table/TableHistoryBalance/tableHistoryBalance.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,mBAAA;AACJ;;AAEA;EACI,qBAAA;EACA,WAAA;EACA,yBAAA;AACJ;AACI;EACI,gCAAA;AACR;AAEI;EACI,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,cAAA;AAAR;AAGI;EACI,kBAAA;EACA,eAAA;EACA,cAAA;EACA,eAAA;AADR;AAII;EACI,iBAAA;EACA,UAAA;AAFR","sourcesContent":[".container {\n    min-height: 650px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.table {\n    margin: 40px 0 20px 0;\n    width: 100%;\n    border-collapse: collapse;\n\n    thead {\n        border-bottom: 2px solid #706F8E;\n    }\n\n    th {\n        padding-bottom: 15px;\n        font-size: 18px;\n        text-align: center;\n        color: #706F8E;\n    }\n\n    td {\n        text-align: center;\n        font-size: 18px;\n        color: #706F8E;\n        padding: 15px 0;\n    }\n\n    .first_item {\n        text-align: start;\n        width: 10%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "tableHistoryBalance_container__bg9+z",
	"table": "tableHistoryBalance_table__pKcv0",
	"first_item": "tableHistoryBalance_first_item__0al1C"
};
export default ___CSS_LOADER_EXPORT___;
