import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../../Assets/logo.svg";
import arow from "../../../Assets/arow.svg";
import styles from "./HeaderOptions.module.scss";

type Props = {
    stepOne?: string,
    stepTwo?: string,
    stepThree?: string,
    id?: string
};

const HeaderOptions: FC<Props> = ({ stepOne, stepTwo, stepThree, id}) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    
    const onClick = (name: string | undefined) => {
        if (name === "Список услуг") {
            navigate("/add_service");
        };
    };

    return(
        <div className={styles.header}>
            <div className={styles.item}>
                <img src={logo} alt="логотип" className={styles.logo}/>
                <div className={styles.name}>
                    <h1>АВТОЗВОНКИ</h1>
                    <div className={styles.step}>
                        <p onClick={() => navigate("/")}>{stepOne}</p>
                        <img src={arow} alt="стрелка"/>
                        <p
                            className={
                                pathname === "/add_balance"
                                || pathname === "/add_phone"
                                || pathname === "/add_service"
                                || pathname === `/edit_token/${id}`
                                || pathname === "/edit_profile"
                            ? 
                                styles.active : ""
                            }
                            onClick={() => onClick(stepTwo)}
                        >
                            {stepTwo}
                        </p>
                        {pathname === `/confirm/${id}` &&
                            <img src={arow} alt="стрелка"/>
                        }
                        {pathname === `/confirm/${id}` &&
                            <p className={
                                pathname === `/confirm/${id}` ? styles.active : ""
                            }
                            >
                                {stepThree}
                            </p>
                        }
                    </div>
                </div>
            </div>
            <div className={styles.back}>
                <p onClick={() => navigate("/")}>ВЕРНУТЬСЯ В ЛИЧНЫЙ КАБИНЕТ</p>
            </div>
        </div>
    );
};

export default HeaderOptions;