import {
    AddBalanceResponse,
    AppendTelNumResponse,
    GetHistoryBalanceResponse,
    GetHistoryCallsResponse,
    GetListStatisticOutCallResponse,
    GetServicesResponse
    } from "../../Types/account/accountResponse.type";
import account, { BASE_END_POINT } from "../config";
import { MainUserResponse } from "../../Types/user.type";

export const accountServices = {
    async appendTelNum(phone: string): Promise<AppendTelNumResponse> {
        try {
            const response = await account.post(BASE_END_POINT, {
                action: "append_tel_num",
                tel_num: phone
            });
            return response.data;
        } catch(error: any) {
            return error;
        };
    },
    async deleteContact(phone: string): Promise<AppendTelNumResponse> {
        try {
            const response = await account.post(BASE_END_POINT, {
                action: "pop_tel_num",
                tel_num: phone
            });
            return response.data;
        } catch(error: any) {
            return error;
        };
    },
    async getServices(): Promise<GetServicesResponse> {
        try {
            const response = await account.post(BASE_END_POINT, {
                action: "list_service"
            });
            return response.data;
        } catch(error: any) {
            return error;
        };
    },
    async getHistoryCalls(page: string, sort: string, direction: string): Promise<GetHistoryCallsResponse> {
        try {
            const response = await account.post(BASE_END_POINT, {
                action: "list_history_out_call",
                back: direction,
                number_page: page,
                sorted_by: sort
            });
            return response.data;
        } catch(error: any) {
            return error
        };
    },
    async getHistoryBalance(page: string): Promise<GetHistoryBalanceResponse> {
        try {
            const response = await account.post(BASE_END_POINT, {
                action: "list_history_balance",
                number_page: page
            });
            return response.data;
        } catch(error: any) {
            return error;
        };
    },
    async getMainUser(): Promise<MainUserResponse> {
        const response = await account.post(BASE_END_POINT, {
            action: "list_main_page_tel_num"
        });
        return response.data;
    },
    async addBalance(amount: string): Promise<AddBalanceResponse> {
        try {
            const response = await account.post(BASE_END_POINT, {
                action: "to_pay",
                amount: amount
            });
            return response.data;
        } catch(error: any) {
            return error;
        };
    },
    async getStatisticOutCall(period: number): Promise<GetListStatisticOutCallResponse> {
        const response = await account.post(BASE_END_POINT, {
            action: "list_statistic_out_call",
            period: period
        });
        return response.data;
    }
};