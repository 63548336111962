import { FC } from "react";
import { useAppSelector } from "../Redux/Hooks/hook";
import { getStep, isLoggedIn } from "../Redux/Store/auth/selector";
import ConfirmationPage from "./Pages/ConfirmationPage/ConfirmationPage";
import LoginPage from "./Pages/LoginPage/LoginPage";
import CreateUserPage from "./Pages/CreateUserPage/CreateUserPage";
import EndLoginPage from "./Pages/EndLoginPage/EndLoginPage";
import HeaderLogin from "./Layouts/HeaderLogin/HeaderLogin";
import { Navigate } from "react-router-dom";

const Authorization: FC = () => {
    const isAuth = useAppSelector(isLoggedIn);
    // Шаги авторизации
    const step = useAppSelector(getStep);

    if (isAuth) {
        return (
            <Navigate to="/" />
        );
    };

    return(
        <>
            <HeaderLogin stepOne="Вход" stepTwo="Код" stepThree="Личный кабинет" steps={step}/>
            {step === "auth" && <LoginPage />}
            {step === "confirmation" && <ConfirmationPage />}
            {step === "createUser" && <CreateUserPage />}
            {step === "endLogin" && <EndLoginPage />}
        </>
    );
};

export default Authorization;