const transormArraysToObject = (array: Array<Array<string>>): any => {
    const result: any = [];
    array.forEach(element => {
        result.push({
            value: element[1],
            label: element[0]
        })
    });
    return result;
};

export default transormArraysToObject;