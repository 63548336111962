import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MainUser } from "../../../Types/user.type";
import { Requisite } from "../../../Types/account/account.type";

type State = {
    entities: Requisite
};

const initialState: State = {
    entities: {
        user: "",
        address: "",
        tel_fax: "",
        e_mail: "",
        kpp: "",
        ogrn: "",
        bank: "",
        payment_account: "",
        corr_account: "",
        bik: ""
    }
};

const requisiteUserSlice = createSlice({
    name: "requisiteUser",
    initialState,
    reducers: {
        requisiteUserReceved: (state, action: PayloadAction<Requisite>) => {
            state.entities = action.payload;
        }
    }
});


const { reducer: requisiteUserReducer, actions } = requisiteUserSlice;
export const { requisiteUserReceved } = actions;

export default requisiteUserReducer;