import { ListHistoryBalance } from "../../../Types/account/account.type";
import { RootState } from "../store";

export const getListHistoryBalance = (state: RootState): ListHistoryBalance[] => {
    return state.historyBalance.historyBalance;
};

export const getMaxPage = (state: RootState): number => {
    return state.historyBalance.maxPage;
};
