export const navigationListData = [
    {id: "services", name: "Услуги"},
    {id: "historyBalance", name: "История пополнения баланса"},
    {id: "historyCalls", name: "История звонков"}
];

export const sortDateStats = [
    {value: "7", label: "НЕДЕЛЯ"},
    {value: "30", label: "30 ДНЕЙ"},
    {value: "90", label: "90 ДНЕЙ"},
    {value: "180", label: "180 ДНЕЙ"}
];

export const requisiteData = [
    {name: "Адрес", value: "address", minLength:0, maxLength: 100, pattern: /\w/, errorMessage: ""},
    {name: "Тел/факс", value: "tel_fax", minLength:0, maxLength: 15, pattern: /\w/, errorMessage: ""},
    {name: "E-mail", value: "e_mail", minLength:0, maxLength: 100, pattern: /^\S+@\S+\.\S+$/g, errorMessage: "Не корректно введена почта"},
    {name: "КПП", value: "kpp", minLength:0, maxLength: 9, pattern:/^\d+$/, errorMessage: "Введите только цифры"},
    {name: "ОГРН", value: "ogrn", minLength:0, maxLength: 20, pattern: /\w/, errorMessage: "Введите только цифры"},
    {name: "Банк", value: "bank", minLength:0, maxLength: 100, pattern: /\w/, errorMessage: ""},
    {name: "Расчётный счёт", value: "payment_account", minLength:0, maxLength: 20, pattern: /^\d+$/, errorMessage: "Введите только цифры"},
    {name: "Корр. счёт", value: "corr_account", minLength:0, maxLength: 20, pattern: /^\d+$/, errorMessage: "Введите только цифры"},
    {name: "Бик", value: "bik", minLength:0, maxLength: 9, pattern: /^\d+$/, errorMessage: "Введите только цифры"}, 
];

export const sortArrays = [
    {value: "call_time", label: "ВРЕМЕНИ ЗВОНКА"},
    {value: "price", label: "СТОИМОСТИ ЗВОНКА"},
    {value: "operator", label: "ОПЕРАТОРУ СВЯЗИ"}
];

export const directionArrays = [
    {value: "-", label: "ПО УБЫВАНИЮ"},
    {value: "+", label: "ПО ВОЗРАСТАНИЮ"}
];


export const URL_INSTRUCTIONS_API = "https://docs.google.com/document/d/1XD-8UIVWLi8eKyagYhNVR1omQmGalygstbmYuvd2HWI/edit?usp=sharing";