import { Requisite } from "../../Types/account/account.type";
import { ChangeProfileResponse, getInnUserResponse, getListTimeZonesResponse, getRequisiteResponse } from "../../Types/account/accountResponse.type";
import account, { BASE_END_POINT } from "../config";

const editProfileServices = {
    async getListTimeZones(): Promise<getListTimeZonesResponse> {
        const response = await account.post(BASE_END_POINT, {
            action: "list_time_zone"
        });
        return response.data;
    },
    async getInnUser(): Promise<getInnUserResponse> {
        const response = await account.post(BASE_END_POINT, {
            action: "view_inn"
        });
        return response.data;
    },
    async getRequisite(): Promise<getRequisiteResponse> {
        const response = await account.post(BASE_END_POINT, {
            action: "view_requisites"
        });
        return response.data;
    },
    async editRequisite(data: Requisite): Promise<ChangeProfileResponse> {
        const response = await account.post(BASE_END_POINT, {
            action: "create_requisites",
            address: data.address,
            tel_fax: data.tel_fax,
            e_mail: data.e_mail,
            kpp: data.kpp,
            ogrn: data.ogrn,
            bank: data.bank,
            payment_account: data.payment_account,
            corr_account: data.corr_account,
            bik: data.bik
        });
        return response.data;
    },
    async changeUserName(name: string): Promise<ChangeProfileResponse> {
        const response = await account.post(BASE_END_POINT, {
            action: "update_contact_customer",
            name: name
        });
        return response.data;
    },
    async changeCompanyName(name: string): Promise<ChangeProfileResponse> {
        const response = await account.post(BASE_END_POINT, {
            action: "update_contact_customer",
            company_name: name
        });
        return response.data;
    },
    async changeTimeZone(time_zone: string): Promise<ChangeProfileResponse> {
        const response = await account.post(BASE_END_POINT, {
            action: "update_contact_customer",
            time_zone: time_zone
        });
        return response.data;
    },
    async changeInn(inn: string): Promise<ChangeProfileResponse> {
        const response = await account.post(BASE_END_POINT, {
            action: "create_new_inn",
            inn: inn
        });
        return response.data;
    }
};

export default editProfileServices;