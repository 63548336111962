import axios from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import activeServicesService from "../../../../Services/accountServices/activeServices.service";
import { reloadReceved } from "../../../Store/mainUser/mainUser";
import { IupdateActiveServiceCustomer } from "../../../../Types/account/account.type";
import {
    accountCreateActiveServiceAction,
    accountDeleteActiveServiceAction,
    accountDeleteActiveTokenAction,
    accountPauseActiveServiceAction,
    accountUpdateActiveServiceAction
} from "./activeServicesActions";
import {
    AccountCreateActiveService,
    AccountDeleteActiveService,
    AccountDeleteActiveToken,
    AccountPauseActiveService,
    AccountUpdateActiveService,
    DataCreateActiveService,
    DataDeleteActiveService,
    DataDeleteActiveToken,
    DataPauseActiveService
} from "./activeServices.types.saga";
import { isLoggedInReceved } from "../../../Store/auth/isLoggedIn";
import { errorsMessageAxiosReceved, stateErrorsMessageReceved } from "../../../Store/errorsMessage/errorsMessage";

function* deleteActiveServiceSaga(data: PayloadAction<DataDeleteActiveService>) {
    try {
        const response: AccountDeleteActiveService = yield call(activeServicesService.deleteActiveService, data.payload.id);

        if (response.status === "200") {
            yield data.payload.setActive(false);
            yield put(reloadReceved());
        };
        
        if (response.status === "401") {
            yield put(isLoggedInReceved(false));
            yield put(errorsMessageAxiosReceved("Необходимо авторизоваться"));
            yield put(stateErrorsMessageReceved({
                open: true,
                type: "error"
            }));
        };
    } catch(error) {
        if(axios.isAxiosError(error)) {
            if (error.response) {
                yield put(errorsMessageAxiosReceved("Проблемы с сервером, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else if(error.request) {
                yield put(errorsMessageAxiosReceved("Проблемы с соединением, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else {
            };
        };
    };
};

function* updateActiveServiceSaga(data: PayloadAction<IupdateActiveServiceCustomer>) {
    try {
        const response: AccountUpdateActiveService = yield call(activeServicesService.updateActiveServiceCustomer, data.payload);

        if (response.status === "200") {
            yield put(reloadReceved());
        };

        if (response.status === "401") {
            yield put(isLoggedInReceved(false));
            yield put(errorsMessageAxiosReceved("Необходимо авторизоваться"));
            yield put(stateErrorsMessageReceved({
                open: true,
                type: "error"
            }));
        };
    } catch(error) {
        if(axios.isAxiosError(error)) {
            if (error.response) {
                yield put(errorsMessageAxiosReceved("Проблемы с сервером, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else if(error.request) {
                yield put(errorsMessageAxiosReceved("Проблемы с соединением, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else {
            };
        };
    };
};

function* createActiveServiceSaga(data: PayloadAction<DataCreateActiveService>) {
    try {
        const response: AccountCreateActiveService = yield call(activeServicesService.createActiveService, data.payload.id_service, data.payload.desc, data.payload.token_key);

        if (response.status === "200") {
            yield put(reloadReceved());
            if (data.payload.navigate) {
                data.payload.navigate("/personal_account");
            }
        };

        if (response.status === "401") {
            yield put(isLoggedInReceved(false));
            yield put(errorsMessageAxiosReceved("Необходимо авторизоваться"));
            yield put(stateErrorsMessageReceved({
                open: true,
                type: "error"
            }));
        };
    } catch(error) {
        if(axios.isAxiosError(error)) {
            if (error.response) {
                yield put(errorsMessageAxiosReceved("Проблемы с сервером, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else if(error.request) {
                yield put(errorsMessageAxiosReceved("Проблемы с соединением, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else {
            };
        };
    };
};

function* deleteActiveTokenSaga(data: PayloadAction<DataDeleteActiveToken>) {
    try {
        const response: AccountDeleteActiveToken = yield call(activeServicesService.deleteActiveToken, data.payload.id_token);

        if (response.status === "200") {
            yield put(reloadReceved());
            yield data.payload.setActive(false);
        };

        if (response.status === "401") {
            yield put(isLoggedInReceved(false));
            yield put(errorsMessageAxiosReceved("Необходимо авторизоваться"));
            yield put(stateErrorsMessageReceved({
                open: true,
                type: "error"
            }));
        };
    } catch(error) {
        if(axios.isAxiosError(error)) {
            if (error.response) {
                yield put(errorsMessageAxiosReceved("Проблемы с сервером, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else if(error.request) {
                yield put(errorsMessageAxiosReceved("Проблемы с соединением, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else {
            };
        };
    };
};

function* pauseActiveServiceSaga(data: PayloadAction<DataPauseActiveService>) {
    try {
        const response: AccountPauseActiveService = yield call(activeServicesService.pauseActiveService, data.payload.id_service, data.payload.pause_service);

        if (response.status === "200") {
            yield put(reloadReceved());
        };

        if (response.status === "401") {
            yield put(isLoggedInReceved(false));
            yield put(errorsMessageAxiosReceved("Необходимо авторизоваться"));
            yield put(stateErrorsMessageReceved({
                open: true,
                type: "error"
            }));
        };
    } catch(error) {
        if(axios.isAxiosError(error)) {
            if (error.response) {
                yield put(errorsMessageAxiosReceved("Проблемы с сервером, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else if(error.request) {
                yield put(errorsMessageAxiosReceved("Проблемы с соединением, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else {
            };
        };
    };
};

function* activeServicesSaga() {
    yield all([
        takeLatest(accountDeleteActiveServiceAction, deleteActiveServiceSaga),
        takeLatest(accountUpdateActiveServiceAction, updateActiveServiceSaga),
        takeLatest(accountCreateActiveServiceAction, createActiveServiceSaga),
        takeLatest(accountDeleteActiveTokenAction, deleteActiveTokenSaga),
        takeLatest(accountPauseActiveServiceAction, pauseActiveServiceSaga),
    ]);
};

export default activeServicesSaga;