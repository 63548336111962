// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_modal__jWKtm {\n  height: 100vh;\n  width: 100vw;\n  background-color: rgba(255, 255, 255, 0.6);\n  position: fixed;\n  top: 0;\n  left: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  opacity: 0;\n  pointer-events: none;\n}\n\n.modal_modal_active__b4bSu {\n  opacity: 1;\n  pointer-events: all;\n}", "",{"version":3,"sources":["webpack://./src/Components/Ui/ModalWindows/Modal/modal.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;EACA,0CAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,UAAA;EACA,oBAAA;AACJ;;AAEA;EACI,UAAA;EACA,mBAAA;AACJ","sourcesContent":[".modal {\n    height: 100vh;\n    width: 100vw;\n    background-color: rgba(255, 255, 255, 0.6);\n    position: fixed;\n    top: 0;\n    left: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    opacity: 0;\n    pointer-events: none;\n}\n\n.modal_active {\n    opacity: 1;\n    pointer-events: all;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal_modal__jWKtm",
	"modal_active": "modal_modal_active__b4bSu"
};
export default ___CSS_LOADER_EXPORT___;
