import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { type } from "os";

export interface ISetOpen {
    open: boolean,
    type: "error" | "success"
}

type State = {
    entities: string,
    errorMessageAxios: string,
    setOpen: ISetOpen
};

const initialState: State = {
    entities: "",
    errorMessageAxios: "",
    setOpen: {
        open: false,
        type: "error"
    }
};

const errorsMessageSlice = createSlice({
    name: "errorsMessage",
    initialState,
    reducers: {
        errorsMessageReceved: (state, action: PayloadAction<string>) => {
            state.entities = action.payload;
        },
        errorsMessageAxiosReceved: (state, action: PayloadAction<string>) => {
            state.errorMessageAxios = action.payload;
        },
        stateErrorsMessageReceved: (state, action: PayloadAction<ISetOpen>) => {
            state.setOpen = action.payload
        }
    }
});


const { reducer: errorsMessageReducer, actions } = errorsMessageSlice;
export const { errorsMessageReceved, errorsMessageAxiosReceved, stateErrorsMessageReceved } = actions;

export default errorsMessageReducer;