import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListHistoryCalls } from "../../../Types/account/account.type";

type State = {
    entities: string,
}

const initialState: State = {
    entities: "",
};

const innUserSlice = createSlice({
    name: "innUser",
    initialState,
    reducers: {
        innUserReceved: (state, action: PayloadAction<string>) => {
            state.entities = action.payload;
        }
    }
});


const { reducer: innUserReducer, actions } = innUserSlice;
export const { innUserReceved } = actions;

export default innUserReducer;