import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../Redux/Hooks/hook";
import { accountPauseActiveServiceAction } from "../../../../Redux/Saga/account/activeServicesSaga/activeServicesActions";
import { ListActiveService } from "../../../../Types/account/account.type";
import ModalConfirmDeleteService from "../../ModalWindows/ModalConfirmDeleteService/ModalConfirmDeleteService";
import transformDate from "../../../../Utils/transormDate";
import editIcon from "../../../../Assets/editIcon.svg";
import deleteIcon from "../../../../Assets/deleteIcons.svg";
import stopIcon from "../../../../Assets/stopIcon.svg";
import playIcon from "../../../../Assets/playIcon.svg";
import styles from "./tableActiveServiceList.module.scss";

type Props = {
    data: ListActiveService[],
    name: string,
    active: boolean,
    setName: React.Dispatch<React.SetStateAction<string>>
    setActive: React.Dispatch<React.SetStateAction<boolean>>
};

const TableActiveServiceList: FC<Props> = ({ data, setName, name, setActive, active }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const onSubmit = (id: string, pause_service: "0" | "1") => {
            dispatch(accountPauseActiveServiceAction({
                id_service: id,
                pause_service: pause_service
            }));
    };

    return (
        <table className={styles.table}>
            <thead className={styles.table_header}>
                <tr>
                    <th className={styles.first_item}>Название услуги</th>
                    <th>Токен</th>
                    <th>Текущая цена</th>
                    <th>След. цена</th>
                    <th>Кол-во совершённых <br /> звонков за 3 дня</th>
                    <th>Кол-во звонков до <br /> следующей цены</th>
                    <th>Дата подключения</th>
                    <th></th>
                </tr>
            </thead>
            <tbody className={styles.table_body}>
                {data.map((item) => (
                    <tr key={item.id_service}>
                        <td className={styles.first_item}>{item.service_name}</td>
                        <td>
                            <button
                                className={styles.button}
                                onClick={() => navigate(`/edit_token/${item.id_service}`)}
                            >
                                Сгенерировать
                            </button>
                        </td>
                        <td>{item.now_price}</td>
                        <td>{item.next_price}</td>
                        <td>{item.count_call}</td>
                        <td>{item.next_count_call}</td>
                        <td>{transformDate(item.date_time_create)}</td>
                        <td className={styles.options}>
                            {item.pause_service === "0" &&
                                <img src={stopIcon} alt="стоп" onClick={() => onSubmit(item.id_service, "1")}/>
                            }
                            {item.pause_service === "1" &&
                                <img src={playIcon} alt="стоп" onClick={() => onSubmit(item.id_service, "0")}/>
                            }
                            <img src={deleteIcon} alt="удалить" onClick={() => {setActive(true); setName(item.id_service)}}/>
                            {name === item.id_service &&
                                <ModalConfirmDeleteService
                                    name={item.service_name}
                                    id={item.id_service}
                                    active={active}
                                    setActive={setActive}
                                />
                            }
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default TableActiveServiceList;