import { FC } from "react";
import { Link } from "react-scroll";
import styles from "./navigationList.module.scss";

type Props = {
    options: Array<{
        id: string,
        name: string
    }>
};

const NavigationList: FC<Props> = ({ options }) => {
    return (
        <div className={styles.nav}>
            {options.map((item) => (
                <Link activeClass="active" to={item.id} smooth={true} offset={0} duration={500} key={item.id}>
                    {item.name}
                </Link>
            ))}
        </div>
    );
};

export default NavigationList;