import { FC } from "react";
import { useAppDispatch } from "../../../../Redux/Hooks/hook";
import { accountDeleteActiveServiceAction } from "../../../../Redux/Saga/account/activeServicesSaga/activeServicesActions";
import Modal from "../Modal/Modal";
import styles from "./modalConfirmDeleteService.module.scss";

type Props = {
    active: boolean,
    id: string,
    name: string,
    setActive: React.Dispatch<React.SetStateAction<boolean>>
};

const ModalConfirmDeleteService: FC<Props> = ({ active, setActive, name, id }) => {
    const dispatch = useAppDispatch();

    const onSubmit = (id: string) => {
        dispatch(accountDeleteActiveServiceAction({
            id: id,
            setActive: setActive
        }));
    };

    return (
        <Modal active={active} setActive={setActive}>
            <div className={styles.modal_content} onClick={(e) => e.stopPropagation()}>
                <h3>Смена имени пользователя</h3>
                <p>Вы действительно хотите удалить услугу «{name}»?</p>
                <div className={styles.buttons_list}>
                    <button className={styles.button} onClick={() => onSubmit(id)}>Да</button>
                    <button className={styles.button} onClick={() => setActive(false)}>Нет</button>
                </div>
            </div>
        </Modal>
    );
};
 
export default ModalConfirmDeleteService;