import { all, call, takeLatest, SagaReturnType, put, select } from "redux-saga/effects";
import { authServices } from "../../../Services/auth.services";
import { PayloadAction } from "@reduxjs/toolkit";
import { statusAuthReceved, stepReceved } from "../../Store/auth/step";
import { User } from "../../../Types/user.type";
import { authUserSagaAction, checkDigitsAction, createUserAction, endLoginAction, logOutAction, loginUserSagaAction } from "./authSagaActions";
import { getAll } from "../../Store/auth/selector";
import { defaultsReceved, digitsReceved, isMainCustomerReceved, nameCompanyReceved, nameReceved, okRegReceved, phoneReceved, statusLoginReceved } from "../../Store/auth/user";
import { timeZonesReceved } from "../../Store/timeZones/timeZones";
import { DataCheckDigits, DataCreateUser, EndLoginTypeSaga, IlogOut } from "./auth.types.saga";
import { isLoggedInReceved } from "../../Store/auth/isLoggedIn";
import { errorsMessageAxiosReceved, errorsMessageReceved, stateErrorsMessageReceved } from "../../Store/errorsMessage/errorsMessage";
import axios from "axios";

type AuthRegistServiceResponse = SagaReturnType<typeof authServices.registration>;
type AuthLoginServiceResponse = SagaReturnType<typeof authServices.login>;
type AuthConfirmationServiceResponse = SagaReturnType<typeof authServices.confirmation>;
type AuthCreateUserServiceResponse = SagaReturnType<typeof authServices.createUser>;
type AuthEndLoginResponse = SagaReturnType<typeof authServices.endLogin>;
type LogOutResponse = SagaReturnType<typeof authServices.logOut>;

function* authUserSaga(data: PayloadAction<string>) {
    try {
        const response: AuthRegistServiceResponse = yield call(authServices.registration, {
            tel_num: data.payload
        });

        if (response.status === "200") {
            yield put(phoneReceved(response.data.tel_num));
            yield put(statusLoginReceved(response.data.login));
            yield put(errorsMessageReceved(""));
            yield put(stepReceved("confirmation"));
            yield put(statusAuthReceved("regist"));
        };

        if (response.status === "401") {
            if (response.data.error) {
                yield put(errorsMessageReceved("Аккаунт с таким номером уже зарегестрирован"));
            }
        };
    } catch(error) {
        if(axios.isAxiosError(error)) {
            if (error.response) {
                yield put(isLoggedInReceved(false));
                yield put(errorsMessageAxiosReceved("Проблемы с сервером, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else if(error.request) {
                yield put(errorsMessageAxiosReceved("Проблемы с соединением, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else {
            };
        };
    };
};

function* checkDigitsSaga(data: PayloadAction<DataCheckDigits>) {
    const user: User = yield select(getAll);

    try {
        const response: AuthConfirmationServiceResponse = yield call(authServices.confirmation, data.payload.digits, user.tel_num, user.login);

        if (response.status === "200") {
            yield put(okRegReceved(response.data.ok_reg));
            yield put(digitsReceved(data.payload.digits));
            if (user.login === "0") {
                yield put(timeZonesReceved(response.defaults));
                yield put(errorsMessageReceved(""));
                yield put(stepReceved("createUser"));
            }
            if (user.login === "1" && response.defaults_company) {
                yield put(defaultsReceved(response.defaults_company))
                yield put(stepReceved("endLogin"));
                yield put(nameReceved(response.data.name));
                yield put(errorsMessageReceved(""));
                yield put(nameCompanyReceved(response.data.company_name));
            }
            if (user.login === "1" && response.data.is_main_customer === true) {
                yield put(nameReceved(response.data.name));
                yield put(nameCompanyReceved(response.data.company_name));
                yield put(isLoggedInReceved(true));
                yield put(errorsMessageReceved(""));
                yield data.payload.navigate("/");
            }
        };

        if (response.status === "404") {
                yield put(errorsMessageReceved("Введите правильный код"));
        };
    } catch(error) {
        if(axios.isAxiosError(error)) {
            if (error.response) {
                yield put(isLoggedInReceved(false));
                yield put(errorsMessageAxiosReceved("Проблемы с сервером, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else if(error.request) {
                yield put(errorsMessageAxiosReceved("Проблемы с соединением, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else {
            };
        };
    };
};

function* createUserSaga(data: PayloadAction<DataCreateUser>) {
    const user: User = yield select(getAll);

    try {
        const response: AuthCreateUserServiceResponse = yield call(authServices.createUser, {
            tel_num: user.tel_num,
            name: data.payload.data.name,
            company_name: data.payload.data.company_name,
            ok_reg: user.ok_reg,
            time_zone: data.payload.data.time_zone,
            digits: user.digits
        })

        if (response.status === "200") {
            yield put(isLoggedInReceved(true));
            data.payload.navigate("/");
        };
    } catch(error) {
        if(axios.isAxiosError(error)) {
            if (error.response) {
                yield put(isLoggedInReceved(false));
                yield put(errorsMessageAxiosReceved("Проблемы с сервером, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else if(error.request) {
                yield put(errorsMessageAxiosReceved("Проблемы с соединением, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else {
            };
        };
    };
};

function* loginUserSaga(data: PayloadAction<string>) {
    try {
        const response: AuthLoginServiceResponse = yield call(authServices.login, {
            tel_num: data.payload
        });
        if (response.status === "200") {
            yield put(phoneReceved(response.data.tel_num));
            yield put(statusLoginReceved(response.data.login));
            yield put(errorsMessageReceved(""));
            yield put(stepReceved("confirmation"));
            yield put(statusAuthReceved("login"));
        };
        if (response.status === "401") {
            if (response.data.error) {
                yield put(errorsMessageReceved("Аккаунт с таким номером не зарегестрирован"));
            }
        };
    } catch(error) {
        if(axios.isAxiosError(error)) {
            if (error.response) {
                yield put(isLoggedInReceved(false));
                yield put(errorsMessageAxiosReceved("Проблемы с сервером, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else if(error.request) {
                yield put(errorsMessageAxiosReceved("Проблемы с соединением, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else {
            };
        };
    };
}

function* endLogin(data: PayloadAction<EndLoginTypeSaga>) {
    const user: User = yield select(getAll);
    try {
        const response: AuthEndLoginResponse = yield call(authServices.endLogin, {
            tel_num: user.tel_num,
            digits: user.digits,
            id_customer: data.payload.id_customer,
            ok_reg: user.ok_reg
        });
        if (response.status === "200") {
            yield put(nameReceved(response.data.name));
            yield put(nameCompanyReceved(response.data.company_name));
            yield put(phoneReceved(response.data.tel_num));
            yield put(isMainCustomerReceved(response.data.is_main_customer));
            yield put(isLoggedInReceved(true));
            yield data.payload.navigate("/");
        };
    } catch(error) {
        if(axios.isAxiosError(error)) {
            if (error.response) {
                yield put(isLoggedInReceved(false));
                yield put(errorsMessageAxiosReceved("Проблемы с сервером, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else if(error.request) {
                yield put(errorsMessageAxiosReceved("Проблемы с соединением, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else {
            };
        };
    };
}

function* logOut(data: PayloadAction<IlogOut>) {
    try {
        const response: LogOutResponse = yield call(authServices.logOut)
        if (response.status === "200") {
            yield put(stepReceved("auth"));
            yield put(nameReceved(""));
            yield put(nameCompanyReceved(""));
            yield put(phoneReceved(""));
            yield put(okRegReceved(""));
            yield put(digitsReceved(""));
            yield put(defaultsReceved([]));
            yield put(statusLoginReceved(""));
            yield put(isMainCustomerReceved(false));
            yield put(isLoggedInReceved(false));
            yield data.payload.navigate("/auth")
        }
    } catch(error) {
        if(axios.isAxiosError(error)) {
            if (error.response) {
                yield put(isLoggedInReceved(false));
                yield put(errorsMessageAxiosReceved("Проблемы с сервером, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else if(error.request) {
                yield put(errorsMessageAxiosReceved("Проблемы с соединением, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else {
            };
        };
    };
}

function* authSaga() {
    yield all([
        takeLatest(authUserSagaAction.type, authUserSaga),
        takeLatest(checkDigitsAction.type, checkDigitsSaga),
        takeLatest(createUserAction.type, createUserSaga),
        takeLatest(loginUserSagaAction.type, loginUserSaga),
        takeLatest(endLoginAction.type, endLogin),
        takeLatest(logOutAction.type, logOut)
    ]);
};

export default authSaga;


