import { FC } from "react";
import { useAppDispatch } from "../../../../Redux/Hooks/hook";
import { accountDeleteContactAction } from "../../../../Redux/Saga/account/accountSagaActions";
import Modal from "../Modal/Modal";
import transformPhoneToString from "../../../../Utils/transformPhoneToString";
import styles from "./modalDeletePhone.module.scss";

type Props = {
    phone: string,
    active: boolean,
    setActive: React.Dispatch<React.SetStateAction<boolean>>
};

const ModalDeletePhone: FC<Props> = ({ phone, active, setActive }) => {
    const dispatch = useAppDispatch();

    const onDeletePhone = (phone: string) => {
        dispatch(accountDeleteContactAction({
            contact: phone,
            setActive: setActive
        }));
    };

    return (
        <Modal active={active} setActive={setActive}>
            <div className={styles.modal_content} onClick={(e) => e.stopPropagation()}>
                <h3>Удаление номера</h3>
                <p>
                    Вы действительно хотите удалить дополнительный номер  <br />{transformPhoneToString(phone)}?
                </p>
                <div className={styles.buttons_list}>
                    <button className={styles.button}  onClick={() => onDeletePhone(phone)}>Да</button>
                    <button className={styles.button} onClick={() => setActive(false)}>Нет</button>
                </div>
            </div>
        </Modal>
    );
};
 
export default ModalDeletePhone;