import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks/hook";
import { logOutAction } from "../../../Redux/Saga/auth/authSagaActions";
import { getBalance, getMainPage } from "../../../Redux/Store/mainUser/mainUserSelector";
import iconSettings from "../../../Assets/settings.svg";
import iconAddPhone from "../../../Assets/addPhoneIcon.svg";
import iconLogOut from "../../../Assets/logOutIcon.svg";
import style from "./headerPersonalAccount.module.scss";

const HeaderPersonalAccount: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { name } = useAppSelector(getMainPage);
    const balance = useAppSelector(getBalance);

    const logOut = () => {
        dispatch(logOutAction({
            // Передача navigate для роутинга при успешном запросе
            navigate: navigate
        }));
    };

    return(
        <div className={style.header}>
            <div className={style.item}>
                <div className={style.logo}>
                    {name[0]}
                </div>
                <div style={{ marginLeft: 25 }}>
                    <div className={style.options}>
                        <div onClick={() => navigate("/add_balance")}>Пополнить</div>
                        <p onClick={() => navigate("/add_service")}>Подключить услугу</p>
                    </div>
                    <div>БАЛАНС: {balance} р.</div>
                </div>
            </div>
            <div className={style.logOut}>
                <img src={iconSettings}  onClick={() => navigate("/edit_profile")} alt=""/>
                <img className={style.icons} src={iconAddPhone} alt=""/>
                <img className={style.icons} src={iconLogOut} onClick={logOut} alt=""/>
                <p className={style.logout_icon}  onClick={logOut}>Выйти из кабинета</p>
            </div>
        </div>
    );
};

export default HeaderPersonalAccount;