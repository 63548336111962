import { FC, useEffect } from "react";
import MaskedInput from "react-text-mask";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch } from "../../../../Redux/Hooks/hook";
import { accountEditRequisiteAction } from "../../../../Redux/Saga/account/editProfileSaga/editProfileSagaActions";
import { Requisite } from "../../../../Types/account/account.type";
import Modal from "../Modal/Modal";
import styles from "./ModalEditRequisites.module.scss";

type Props = {
    active: boolean,
    requisite: Requisite,
    setActive: React.Dispatch<React.SetStateAction<boolean>>
};

const ModalEditRequisites: FC<Props> = ({ active, setActive, requisite }) => {
    const { control, register, handleSubmit, formState: {errors}, setFocus } = useForm<Requisite>();
    const dispatch = useAppDispatch();

    useEffect(() => {
        setFocus("address");
    }, [ setFocus ]);

    const onSubmit = (data: Requisite) => {
        dispatch(accountEditRequisiteAction({
            requisite: data,
            setActive: setActive
        }));
    };

    return (
        <Modal active={active} setActive={setActive}>
            <form className={styles.modal_content} onClick={(e) => e.stopPropagation()} onSubmit={handleSubmit(onSubmit)}>
                <h3>Смена реквизитов</h3>
                <div>
                    <p>Адрес</p>
                    <div className={styles.form_item}>
                        <input
                            type="text"
                            defaultValue={requisite.address}
                            className={errors.address ? styles.error_input : styles.input}
                            {...register("address", { required: "Поле обязательно к заполнению" })}
                        />
                        {errors.address && <p className={styles.error}>{errors.address.message}</p>}
                    </div>
                </div>
                <div>
                    <p>Телефон</p>
                    <div className={styles.form_item}>
                        <Controller
                                control={control}
                                defaultValue={requisite.tel_fax}
                                name="tel_fax"
                                rules={{ 
                                    required: "Поле обязательно для заполнения",
                                    minLength: {
                                        value: 16,
                                        message: "Введите номер телефона"
                                    }
                                }}
                                render={({
                                    field: { onChange, value}
                                }) => (
                                    <MaskedInput
                                        mask={["+","7",'(', /\d/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                                        placeholder="+7 (***) ***-**-**"
                                        guide={false}
                                        value={value}
                                        onChange={onChange}
                                        className={errors.tel_fax ? styles.error_input : styles.input}
                                    />
                                )}
                        />
                        {errors.tel_fax && <p className={styles.error}>{errors.tel_fax.message}</p>}
                    </div>
                </div>
                <div>
                    <p>E-mail</p>
                    <div className={styles.form_item}>
                        <input
                            type="text"
                            defaultValue={requisite.e_mail}
                            className={errors.e_mail ? styles.error_input : styles.input}
                            {...register("e_mail", {
                                required: "Поле обязательно к заполнению",
                                pattern: { value: /^\S+@\S+\.\S+$/g, message: "Не корректно введена почта"}
                            })}
                        />
                        {errors.e_mail && <p className={styles.error}>{errors.e_mail.message}</p>}
                    </div>
                </div>
                <div>
                    <p>КПП</p>
                    <div className={styles.form_item}>
                        <input
                            type="text"
                            defaultValue={requisite.kpp}
                            className={errors.kpp ? styles.error_input : styles.input}
                            {...register("kpp", {
                                required: "Поле обязательно к заполнению",
                                pattern: { value: /^\d+$/, message: "Введите только цифры"},
                                minLength: { value: 9, message: "КПП состоит из 9 цифр"},
                                maxLength: { value: 9, message: "КПП состоит из 9 цифр"}
                            })}
                        />
                        {errors.kpp && <p className={styles.error}>{errors.kpp.message}</p>}
                    </div>
                </div>
                <div>
                    <p>ОГРН</p>
                    <div className={styles.form_item}>
                        <input
                            type="text"
                            defaultValue={requisite.ogrn}
                            className={errors.ogrn ? styles.error_input : styles.input}
                            {...register("ogrn", {
                                required: "Поле обязательно к заполнению",
                                pattern: { value: /^\d+$/, message: "Введите только цифры"},
                                minLength: { value: 13, message: "ОГРН состоит из 13 цифр"},
                                maxLength: { value: 13, message: "ОГРН состоит из 13 цифр"}
                            })}
                        />
                        {errors.ogrn && <p className={styles.error}>{errors.ogrn.message}</p>}
                    </div>
                </div>
                <div>
                    <p>Банк</p>
                    <div className={styles.form_item}>
                        <input
                            type="text"
                            defaultValue={requisite.bank}
                            className={errors.bank ? styles.error_input : styles.input}
                            {...register("bank", { required: "Поле обязательно к заполнению" })}
                        />
                        {errors.bank && <p className={styles.error}>{errors.bank.message}</p>}
                    </div>
                </div>
                <div>
                    <p>Расчётный счёт</p>
                    <div className={styles.form_item}>
                        <input
                            type="text"
                            defaultValue={requisite.payment_account}
                            className={errors.payment_account ? styles.error_input : styles.input}
                            {...register("payment_account", {
                                required: "Поле обязательно к заполнению",
                                pattern: { value: /^\d+$/, message: "Введите только цифры"},
                                minLength: { value: 20, message: "Расчётный счёт состоит из 20 цифр"},
                                maxLength: { value: 20, message: "Расчётный счёт состоит из 20 цифр"}
                            })}
                        />
                        {errors.payment_account && <p className={styles.error}>{errors.payment_account.message}</p>}
                    </div>
                </div>
                <div>
                    <p>Корр. счёт</p>
                    <div className={styles.form_item}>
                        <input
                            type="text"
                            defaultValue={requisite.corr_account}
                            className={errors.corr_account ? styles.error_input : styles.input}
                            {...register("corr_account", {
                                required: "Поле обязательно к заполнению",
                                pattern: { value: /^\d+$/, message: "Введите только цифры"},
                                minLength: { value: 20, message: "Корр. счёт состоит из 20 цифр"},
                                maxLength: { value: 20, message: "Корр. счёт состоит из 20 цифр"}
                            })}
                        />
                        {errors.corr_account && <p className={styles.error}>{errors.corr_account.message}</p>}
                    </div>
                </div>
                <div>
                    <p>БИК</p>
                    <div className={styles.form_item}>
                        <input
                            type="text"
                            defaultValue={requisite.bik}
                            className={errors.bik ? styles.error_input : styles.input}
                            {...register("bik", {
                                required: "Поле обязательно к заполнению",
                                pattern: { value: /^\d+$/, message: "Введите только цифры"},
                                minLength: { value: 9, message: "БИК состоит из 9 цифр"},
                                maxLength: { value: 9, message: "БИК состоит из 9 цифр"}
                            })}
                        />
                        {errors.bik && <p className={styles.error}>{errors.bik.message}</p>}
                    </div>
                </div>    
                <div className={styles.buttons_list}>
                    <button className={styles.button} onClick={handleSubmit(onSubmit)}>Изменить</button>
                    <button className={styles.button} onClick={() => setActive(false)}>Отмена</button>
                </div>
            </form>
        </Modal>
    );
};
 
export default ModalEditRequisites;