import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

const API_URL = "http://back.checkport.local:3007/";
export const TEST_URL = "http://devfront.checkport.local:7201/"
export const SERVER_URL = "https://front.api.lk.avtozvonki.ru/"
export const BASE_END_POINT = "LkAutoCall";

export const config: AxiosRequestConfig = {
    withCredentials: true,
    baseURL: SERVER_URL,
    headers: {
        "Content-Type" : "application/json"
    }
};

const account: AxiosInstance = axios.create(config);

export default account;