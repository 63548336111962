import axios from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { accountServices } from "../../../Services/accountServices/account.service";
import { servicesReceved } from "../../Store/services/services";
import { countCallsReceved, historyCallsReceved } from "../../Store/historyCalls/historyCalls";
import { balanceReceved, historyBalanceReceved, maxPageReceved } from "../../Store/historyBalance/historyBalance";
import { mainUserReceved, reloadReceved } from "../../Store/mainUser/mainUser";
import { isLoggedInReceved } from "../../Store/auth/isLoggedIn";
import { errorsMessageAxiosReceved, stateErrorsMessageReceved } from "../../Store/errorsMessage/errorsMessage";
import {
    accountAddBalanceAction,
    accountAppendTelNumAction,
    accountDeleteContactAction,
    accountGetHistoryBalance,
    accountGetHistoryCalls,
    accountGetMainUserAction,
    accountGetServicesAction,
    accountGetStatisticOutCallAction
    } from "./accountSagaActions";
import {
    AccountAppendTelNumServices,
    AccountDeleteContactServices,
    AccountGetServices,
    AccountGetHistoryCalls,
    DataDeleteContact,
    DataGetHistoryCalls,
    DataPhone,
    AccountGetHistoryBalance,
    AccountGetMainUser,
    AccountAddBalance,
    AccountGetStatisticOutCall
} from "./account.types.saga";
import { countOutCallReceved, priceOutCallReceved } from "../../Store/statisticOutCall/statisticOutCall";

function* appendTelNumSaga(data: PayloadAction<DataPhone>) {
    try {
        const response:AccountAppendTelNumServices = yield call(accountServices.appendTelNum, data.payload.tel_num);
        
        if (response.status === "200") {
            yield data.payload.navigate("/");
            yield put(reloadReceved());
        };

        if (response.status === "401") {
            yield put(isLoggedInReceved(false));
            yield put(errorsMessageAxiosReceved("Необходимо авторизоваться"));
            yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
            }));
        };
    } catch(error) {
        if(axios.isAxiosError(error)) {
            if (error.response) {
                yield put(errorsMessageAxiosReceved("Проблемы с сервером, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else if(error.request) {
                yield put(errorsMessageAxiosReceved("Проблемы с соединением, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else {
            };
        };
    };
};

function* deleteContactSaga(data: PayloadAction<DataDeleteContact>) {
    try {
        const response: AccountDeleteContactServices = yield call(accountServices.deleteContact, data.payload.contact);
        if (response.status === "200") {
            yield data.payload.setActive(false);
            yield put(reloadReceved());
        };

        if (response.status === "401") {
            yield put(isLoggedInReceved(false));
            yield put(errorsMessageAxiosReceved("Необходимо авторизоваться"));
            yield put(stateErrorsMessageReceved({
                open: true,
                type: "error"
            }));
        };
    } catch(error) {
        if(axios.isAxiosError(error)) {
            if (error.response) {
                yield put(errorsMessageAxiosReceved("Проблемы с сервером, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else if(error.request) {
                yield put(errorsMessageAxiosReceved("Проблемы с соединением, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else {
            };
        };
    };
};

function* getServicesSaga() {
    try {
        const response: AccountGetServices = yield call(accountServices.getServices);

        if (response.status === "200") {
            yield put(servicesReceved(response.data));
        };

        if (response.status === "401") {
            yield put(isLoggedInReceved(false));
            yield put(errorsMessageAxiosReceved("Необходимо авторизоваться"));
            yield put(stateErrorsMessageReceved({
                open: true,
                type: "error"
            }));
        };
    } catch(error) {
        if(axios.isAxiosError(error)) {
            if (error.response) {
                yield put(errorsMessageAxiosReceved("Проблемы с сервером, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else if(error.request) {
                yield put(errorsMessageAxiosReceved("Проблемы с соединением, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else {
            };
        };
    };
};

function* getHistoryCallsSaga(data: PayloadAction<DataGetHistoryCalls>) {
    try {
        const response: AccountGetHistoryCalls = yield call(accountServices.getHistoryCalls, data.payload.page, data.payload.sort, data.payload.direction);
        
        if (response.status === "200") {
            yield put(historyCallsReceved(response.data.calls));
            yield put(countCallsReceved(response.data.count_page));
        };

        if (response.status === "401") {
            yield put(isLoggedInReceved(false));
            yield put(errorsMessageAxiosReceved("Необходимо авторизоваться"));
            yield put(stateErrorsMessageReceved({
                open: true,
                type: "error"
            }));
        };
    } catch(error) {
        if(axios.isAxiosError(error)) {
            if (error.response) {
                yield put(errorsMessageAxiosReceved("Проблемы с сервером, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else if(error.request) {
                yield put(errorsMessageAxiosReceved("Проблемы с соединением, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else {
            };
        };
    };
};

function* getHistoryBalanceSaga(data: PayloadAction<string>) {
    try {
        const response: AccountGetHistoryBalance = yield call(accountServices.getHistoryBalance, data.payload);
        
        if (response.status === "200") {
            yield put(historyBalanceReceved(response.data.list_history_balance));
            yield put(maxPageReceved(response.data.count_page));
            yield put(balanceReceved(response.data.balance));
        };

        if (response.status === "401") {
            yield put(isLoggedInReceved(false));
            yield put(errorsMessageAxiosReceved("Необходимо авторизоваться"));
            yield put(stateErrorsMessageReceved({
                open: true,
                type: "error"
            }));
        };
    } catch(error) {
        if(axios.isAxiosError(error)) {
            if (error.response) {
                yield put(errorsMessageAxiosReceved("Проблемы с сервером, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else if(error.request) {
                yield put(errorsMessageAxiosReceved("Проблемы с соединением, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else {
            };
        };
    };
};

function* getMainUserSaga() {
    try {
        const response: AccountGetMainUser = yield call(accountServices.getMainUser);

        if (response.status === "200") {
            yield put(mainUserReceved(response.data))
        };

        if (response.status === "401") {
            yield put(isLoggedInReceved(false));
            yield put(errorsMessageAxiosReceved("Необходимо авторизоваться"));
            yield put(stateErrorsMessageReceved({
                open: true,
                type: "error"
            }));
        };

        if (response.status === "404") {
            yield put(isLoggedInReceved(false));
            yield put(errorsMessageAxiosReceved("Необходимо авторизоваться"));
            yield put(stateErrorsMessageReceved({
                open: true,
                type: "error"
            }));
        };

    } catch(error) {
        if(axios.isAxiosError(error)) {
            if (error.response) {
                yield put(isLoggedInReceved(false));
                yield put(errorsMessageAxiosReceved("Проблемы с сервером, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
                console.log(error);
            } else if(error.request) {
                yield put(errorsMessageAxiosReceved("Проблемы с соединением, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
                console.log(error);
            } else {
                console.log("error");
            };
        };
    };
};

function* addBalanceSaga(data: PayloadAction<string>) {
    try {
        const response: AccountAddBalance = yield call(accountServices.addBalance, data.payload);
        if (response.status === "200") {
            yield  window.location.href = response.data.formUrl;
        };
        
        if (response.status === "401") {
            yield put(isLoggedInReceved(false));
            yield put(errorsMessageAxiosReceved("Необходимо авторизоваться"));
            yield put(stateErrorsMessageReceved({
                open: true,
                type: "error"
            }));
        };
    } catch(error) {
        if(axios.isAxiosError(error)) {
            if (error.response) {
                yield put(errorsMessageAxiosReceved("Проблемы с сервером, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else if(error.request) {
                yield put(errorsMessageAxiosReceved("Проблемы с соединением, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else {
            };
        };
    };
};

function* getStatisticOutCallSaga(data: PayloadAction<number>) {
    try {
        const response: AccountGetStatisticOutCall = yield call(accountServices.getStatisticOutCall, data.payload);

        if (response.status === "200") {
            yield put(priceOutCallReceved(response.data.price));
            yield put(countOutCallReceved(response.data.counts));
        };

        if (response.status === "401") {
            yield put(isLoggedInReceved(false));
            yield put(errorsMessageAxiosReceved("Необходимо авторизоваться"));
            yield put(stateErrorsMessageReceved({
                open: true,
                type: "error"
            }));
        };
    } catch(error) {
        if(axios.isAxiosError(error)) {
            if (error.response) {
                yield put(errorsMessageAxiosReceved("Проблемы с сервером, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else if(error.request) {
                yield put(errorsMessageAxiosReceved("Проблемы с соединением, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else {
            };
        };
    };
};

function* accountSaga() {
    yield all([
        takeLatest(accountAppendTelNumAction, appendTelNumSaga),
        takeLatest(accountDeleteContactAction, deleteContactSaga),
        takeLatest(accountGetServicesAction, getServicesSaga),
        takeLatest(accountGetHistoryCalls, getHistoryCallsSaga),
        takeLatest(accountGetHistoryBalance, getHistoryBalanceSaga),
        takeLatest(accountGetMainUserAction, getMainUserSaga),
        takeLatest(accountAddBalanceAction, addBalanceSaga),
        takeLatest(accountGetStatisticOutCallAction, getStatisticOutCallSaga)
    ]);
};

export default accountSaga;