import { FC, useEffect } from "react";
import { useAppDispatch } from "../../../../Redux/Hooks/hook";
import { useForm } from "react-hook-form";
import { accountChangeUserNameAction } from "../../../../Redux/Saga/account/editProfileSaga/editProfileSagaActions";
import Modal from "../Modal/Modal";
import styles from "./ModalChangeNameUser.module.scss";

type Props = {
    active: boolean,
    setActive: React.Dispatch<React.SetStateAction<boolean>>
};

type UserName = {
    name: string
};

const ModalChangeNameUser: FC<Props> = ({ active, setActive }) => {
    const { register, handleSubmit, formState: { errors }, setFocus } = useForm<UserName>();
    const dispatch = useAppDispatch();

    useEffect(() => {
        setFocus("name");
    }, [ setFocus ]);

    const onSubmit = (data: UserName) => {
        dispatch(accountChangeUserNameAction({
            name: data.name,
            setActive: setActive
        }));
    };

    return (
        <Modal active={active} setActive={setActive}>
            <div className={styles.modal_content} onClick={(e) => e.stopPropagation()}>
                <h3>Смена имени пользователя</h3>
                <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                    <input
                        type="text"
                        defaultValue={""}
                        className={errors.name ? styles.error_input : styles.input}
                        {...register("name", { required: "Поле обязательно для заполнения" })}
                    />
                    {errors.name && <p className={styles.error}>{errors.name.message}</p>}
                </form>
                <div className={styles.buttons_list}>
                    <button className={styles.button} onClick={handleSubmit(onSubmit)}>Изменить</button>
                    <button className={styles.button} onClick={() => setActive(false)}>Отмена</button>
                </div>
            </div>
        </Modal>
    );
};
 
export default ModalChangeNameUser;