import { FC } from "react";
import { useAppDispatch } from "../../../Redux/Hooks/hook";
import { stepReceved } from "../../../Redux/Store/auth/step";
import styles from "./headerLogin.module.scss";
import logo from "../../../Assets/logo.svg";
import arow from "../../../Assets/arow.svg";

type Props = {
    stepOne: string,
    stepTwo: string,
    stepThree: string,
    steps: string
};

const HeaderLogin: FC<Props> = ({ stepOne, stepTwo, stepThree, steps}) => {
    const dispatch = useAppDispatch();

    return(
        <div className={styles.header}>
            <div className={styles.item}>
                <img src={logo} alt="логотип" className={styles.logo}/>
                <div className={styles.name}>
                    <h1>АВТОЗВОНКИ</h1>
                    <div className={styles.step}>
                        <p
                            onClick={() => dispatch(stepReceved("auth"))}
                            className={steps === "auth" ? styles.active : ""}
                        >
                            {stepOne}
                        </p>
                        {(steps === "confirmation" || steps === "endLogin" || steps === "createUser") &&
                            <img src={arow} alt="стрелка"/>}
                        {(steps === "confirmation" || steps === "endLogin" || steps === "createUser") &&
                            <p
                                className={steps === "confirmation" ? styles.active : ""}
                                onClick={() => dispatch(stepReceved("confirmation"))}
                            >
                                {stepTwo}
                            </p>
                        }
                        {(steps === "createUser" || steps === "endLogin") &&
                            <img src={arow} alt="стрелка"/>}
                        {(steps === "createUser" || steps === "endLogin") &&
                            <p className={(steps === "createUser" || steps === "endLogin") ? styles.active : ""}>{stepThree}</p>
                        }
                    </div>
                </div>
            </div>
            <div className={styles.back}>
                <p onClick={() => window.location.href = "https://avtozvonki.ru/"}>Вернуться на главную страницу</p>
            </div>
        </div>
    );
};

export default HeaderLogin;