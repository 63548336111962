import { FC, useEffect } from "react";
import Modal from "../Modal/Modal";
import { useAppDispatch } from "../../../../Redux/Hooks/hook";
import { useForm } from "react-hook-form";
import styles from "./modalChangeInn.module.scss";
import { accountChangeInnAction } from "../../../../Redux/Saga/account/editProfileSaga/editProfileSagaActions";

type Props = {
    active: boolean,
    setActive: React.Dispatch<React.SetStateAction<boolean>>
};

type InnUser = {
    inn: string
};

const ModalChangeInn: FC<Props> = ({ active, setActive }) => {
    const { register, handleSubmit, formState: { errors }, reset, setFocus } = useForm<InnUser>();
    const dispatch = useAppDispatch();

    useEffect(() => {
        setFocus("inn");
    }, [ setFocus ]);

    const onSubmit = (data: InnUser) => {
        dispatch(accountChangeInnAction({
            inn: data.inn,
            setActive: setActive
        }));
        reset();
    };

    return (
        <Modal active={active} setActive={setActive}>
            <div className={styles.modal_content} onClick={(e) => e.stopPropagation()} onSubmit={handleSubmit(onSubmit)}>
                <h3>Добавление ИНН</h3>
                <form className={styles.form}>
                    <input
                        type="text"
                        defaultValue={""}
                        className={errors.inn ? styles.error_input : styles.input} 
                        {...register("inn", {
                            required: "Поле обязательно к заполнению",
                            pattern: { value: /^\d+$/, message: "Введите только цифры"},
                            minLength: { value: 12, message: "ИНН состоит из 12 цифр"},
                            maxLength: { value: 12, message: "ИНН состоит из 12 цифр"}
                            })
                        }
                    />
                    {errors.inn && <p className={styles.error}>{errors.inn.message}</p>}
                </form>
                <div className={styles.buttons_list}>
                    <button className={styles.button} onClick={handleSubmit(onSubmit)}>Изменить</button>
                    <button className={styles.button} onClick={() => setActive(false)}>Отмена</button>
                </div>
            </div>
        </Modal>
    );
};
 
export default ModalChangeInn;