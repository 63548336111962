import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../../Types/user.type";
import { DefaultsCompany } from "../../../Types/user.type";
import { ListContact } from "../../../Types/account/account.type";

const initialState: User = {
    tel_num: "",
    login: "",
    name: "",
    nameCompany: "",
    ok_reg: "",
    digits: "",
    is_main_customer: true,
    listContacts: [],
    defaults: [],
    isLoggedIn: false
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        phoneReceved: (state, action: PayloadAction<string>) => {
            state.tel_num = action.payload;
        },
        statusLoginReceved: (state, action: PayloadAction<string>) => {
            state.login = action.payload;
        },
        digitsReceved: (state, action: PayloadAction<string>) => {
            state.digits = action.payload;
        },
        okRegReceved: (state, action: PayloadAction<string>) => {
            state.ok_reg = action.payload;
        },
        nameReceved: (state, action: PayloadAction<string>) => {
            state.name = action.payload;
        },
        nameCompanyReceved: (state, action: PayloadAction<string>) => {
            state.nameCompany = action.payload;
        },
        defaultsReceved: (state, action: PayloadAction<DefaultsCompany[]>) => {
            state.defaults = action.payload;
        },
        isMainCustomerReceved: (state, action: PayloadAction<boolean>) => {
            state.is_main_customer = action.payload;
        },
        listContactsReceved: (state, action: PayloadAction<ListContact[]>) => {
            state.listContacts = action.payload;
        }
    }
});


const { reducer: userReducer, actions } = userSlice;
export const {
    phoneReceved,
    statusLoginReceved,
    digitsReceved,
    okRegReceved,
    nameReceved,
    nameCompanyReceved,
    defaultsReceved,
    isMainCustomerReceved,
    listContactsReceved
} = actions;

export default userReducer;