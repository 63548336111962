import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks/hook"
import { authUserSagaAction, loginUserSagaAction } from "../../../Redux/Saga/auth/authSagaActions";
import MaskedInput from 'react-text-mask'
import { getErrorsMessage } from "../../../Redux/Store/errorsMessage/errorsMessageSelector";
import { errorsMessageReceved } from "../../../Redux/Store/errorsMessage/errorsMessage";
import { Phone } from "../../../Types/auth/auth.type";
import convertPhoneNumber from "../../../Utils/transformTelNum";
import styles from "./login.module.scss";

const LoginPage:FC = () => {
    const { control, handleSubmit, formState: { errors }, watch } = useForm<Phone>();
    // Сообщение об ошибке с сервера при регистрации существующего номера
    const errorsResponse = useAppSelector(getErrorsMessage);
    const dispatch = useAppDispatch();

    // Для очистки сообщения с сервера
    useEffect(() => {
        watch(() => {
            dispatch(errorsMessageReceved(""));
        });
    }, [watch]);
    
    const onLogin = (data: Phone) => {
        // Функция для преобразования номера телефона для отправки на сервер
        const phone = convertPhoneNumber(data.tel_num);
        dispatch(loginUserSagaAction(phone));
    };
    
    const onRegist = (data: Phone) => {
        // Функция для преобразования номера телефона для отправки на сервер
        const phone = convertPhoneNumber(data.tel_num);
        dispatch(authUserSagaAction(phone));
    };

    return(
        <div className={styles.main}>
            <p className={styles.back}>Вернуться на главную страницу</p>
            <div className={styles.card}>
                <h2>Вход в личный кабинет</h2>
                <p>Введите номер телефона, на который вам поступит звонок, после чего вам необходимо
                    будет ввести последниче четыре цифры номера, с которого вам поступил звонок.
                </p>
                <form className={styles.form} onSubmit={handleSubmit(onLogin)}>
                    <Controller
                        control={control}
                        defaultValue={""}
                        name="tel_num"
                        rules={{ 
                            required: "Поле обязательно для заполнения",
                            minLength: {
                                value: 16,
                                message: "Введите номер телефона"
                            }
                        }}
                        render={({
                            field: { onChange, value}
                        }) => (
                            <MaskedInput
                                mask={["+","7",'(', /\d/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                                placeholder="+7 (***) ***-**-**"
                                guide={false}
                                value={value}
                                autoFocus={true}
                                onChange={onChange}
                                className={errors.tel_num ? styles.error_input : styles.input}
                            />
                        )}
                    />
                    {errors.tel_num && <p className={styles.error}>{errors.tel_num.message}</p>}
                    {errorsResponse && !errors.tel_num &&<p className={styles.error}>{errorsResponse}</p>}
                </form>
                <div className={styles.buttons_list}>
                    <button className={styles.button} onClick={handleSubmit(onLogin)}>Войти</button>
                    <button className={styles.button} onClick={handleSubmit(onRegist)}>Регистрация</button>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;