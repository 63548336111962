import { FC } from "react";
import { ListHistoryBalance } from "../../../../Types/account/account.type";
import { Pagination } from "@mui/material";
import transformDate from "../../../../Utils/transormDate";
import styles from "./tableHistoryBalance.module.scss";

type Props = {
    data: ListHistoryBalance[],
    page: number,
    maxCount: number,
    handleChange: (event: React.ChangeEvent<unknown>, value: number) => void,
};

const TableHistoryBalance: FC<Props> = ({ data, page, maxCount, handleChange }) => {
    return (
        <div className={styles.container}>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th style={{ width: 10}}>№</th>
                        <th>Дата и время поступления</th>
                        <th>Тип поступления</th>
                        <th>Сумма</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index}>
                            <td>{item.number}</td>
                            <td>{transformDate(item.date_time_create)}</td>
                            <td>{item.payment_source}</td>
                            <td>{item.debit}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagination page={page} count={maxCount} size="large" shape="rounded" onChange={handleChange}/>
        </div>
    );
};
 
export default TableHistoryBalance;