import {createAction} from "@reduxjs/toolkit";
import { DataCheckDigits, DataCreateUser, EndLoginTypeSaga, IlogOut } from "./auth.types.saga";

export const authUserSagaAction =
    createAction<string>("checkport/authorization/REGIST");

export const loginUserSagaAction =
    createAction<string>("checkport/authorization/LOGIN");
    
export const checkDigitsAction =
    createAction<DataCheckDigits>("checkport/authorization/CHECK_DIGITS");

export const createUserAction =
    createAction<DataCreateUser>("checkport/authorization/CREATE_USER");

export const endLoginAction =
    createAction<EndLoginTypeSaga>("checkport/authorization/END_LOGIN");

export const logOutAction =
    createAction<IlogOut>("checkport/LOG_OUT");