import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type IsLoggedIn = {
    entities: boolean
};

const initialState: IsLoggedIn = {
    entities: false
};

const isLoggedInSlice = createSlice({
    name: "isLoggedIn",
    initialState,
    reducers: {
        isLoggedInReceved: (state, action: PayloadAction<boolean>) => {
            state.entities = action.payload;
        }
    }
});


const { reducer: isLoggedInReducer, actions } = isLoggedInSlice;
export const { isLoggedInReceved } = actions;

export default isLoggedInReducer;