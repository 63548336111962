import { FC } from "react";
import styles from "./modal.module.scss";

type Props = {
    active: boolean,
    setActive: React.Dispatch<React.SetStateAction<boolean>>, 
    children: JSX.Element
};

// Контейнер для модальных окон
const Modal: FC<Props> = ({ active, setActive, children }) => {
    return (
        <div className={active ? styles.modal+ " " + styles.modal_active : styles.modal} onClick={() => setActive(false)}>
            {children}
        </div>
    );
};
 
export default Modal;