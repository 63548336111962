import { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks/hook";
import { getMainPage, getRequisiteUser } from "../../../Redux/Store/mainUser/mainUserSelector";
import { getInnUser } from "../../../Redux/Store/innUser/innUserSelector";
import { accountGetInnUserAction, accountGetRequisiteAction } from "../../../Redux/Saga/account/editProfileSaga/editProfileSagaActions";
import { accountGetMainUserAction } from "../../../Redux/Saga/account/accountSagaActions";
import ModalChangeTimeZone from "../../Ui/ModalWindows/ModalChangeTimeZone/ModalChangeTimeZone";
import ModalChangeInn from "../../Ui/ModalWindows/ModalChangeInn/ModalChangeInn";
import ModalChangeNameCompany from "../../Ui/ModalWindows/ModalChangeCompanyName/ModalChangeCompanyName";
import HeaderOptions from "../../Layouts/HeaderOptions/HeaderOptions";
import ModalChangeNameUser from "../../Ui/ModalWindows/ModalChangeNameUser/ModalChangeNameUser";
import ModalEditRequisites from "../../Ui/ModalWindows/ModalEditRequisites/ModalEditRequisites";
import styles from "./EditProfile.module.scss";

const EditProfile: FC = () => {
    const [open, setOpen] = useState(false);
    const [name, setName] = useState("");
    const dispatch = useAppDispatch();
    const user = useAppSelector(getMainPage);
    const innUser = useAppSelector(getInnUser);
    const requisite = useAppSelector(getRequisiteUser);

    // Повторный запрос при изменении данных
    useEffect(() => {
        dispatch(accountGetMainUserAction());
        dispatch(accountGetInnUserAction());
        dispatch(accountGetRequisiteAction());
    }, [open]);

    return (
        <>
            <HeaderOptions stepOne="Личный кабинет" stepTwo="Настройка профиля"/>
            <div className={styles.main}>
                <div className={styles.block}>
                    <h2>Данные профиля</h2>
                    <div className={styles.item}>
                        <div className={styles.name}>Имя: <p>{user.name}</p></div>
                        <button  onClick={() => {setOpen(true); setName("name")}}>Изменить имя</button>
                        {name === "name" &&
                            <ModalChangeNameUser active={open} setActive={setOpen}/>
                        }
                    </div>
                    <div className={styles.item}>
                        <div className={styles.name}>Часовой пояс: <p>{user.utc_name}</p></div>
                        <button onClick={() => {setOpen(true); setName("time_zone")}}>Изменить часовой пояс</button>
                        {name === "time_zone" &&
                            <ModalChangeTimeZone active={open} setActive={setOpen}/>
                        }
                    </div>
                </div>
                <div className={styles.block}>
                    <h2>Данные компании</h2>
                    <div className={styles.item}>
                        <div className={styles.name}>Название: <p>{user.company_name}</p></div>
                        <button onClick={() => {setOpen(true); setName("company_name")}}>Изменить название</button>
                        {name === "company_name" &&
                            <ModalChangeNameCompany active={open} setActive={setOpen} />
                        }
                    </div>
                    <div className={styles.item}>
                        <div className={styles.name}>ИНН: <p>{innUser}</p></div>
                        <button onClick={() => {setOpen(true); setName("inn")}}>
                            {innUser ? "Изменить ИНН" : "Заполнить ИНН"}
                        </button>
                        {name === "inn" &&
                            <ModalChangeInn active={open} setActive={setOpen}/>
                        }
                    </div>
                    <div className={styles.item}>
                        <h3>Реквизиты</h3>
                        {requisite.tel_fax &&
                            <div className={styles.requisite}>
                                <div>Исполнитель: <p>{user.company_name}</p></div>
                                <div>Адрес:<p>{requisite.address}</p></div>
                                <div>Тел/факс: <p>{requisite.tel_fax}</p></div>
                                <div>E-mail: <p>{requisite.e_mail}</p></div>
                                <div>КПП: <p>{requisite.kpp}</p></div>
                                <div>ОГРН: <p>{requisite.ogrn}</p></div>
                                <div>Банк: <p>{requisite.bank}</p></div>
                                <div>Расчётный счёт: <p>{requisite.payment_account}</p></div>
                                <div>Корр. счет: <p>{requisite.corr_account}</p></div>
                                <div>БИК: <p>{requisite.bik}</p></div>
                            </div>
                        }
                        <button onClick={() => {setOpen(true); setName("requisite")}}>
                            {requisite.tel_fax ? "Изменить реквизиты" : "Заполнить реквизиты"}
                        </button>
                        {name === "requisite" &&
                            <ModalEditRequisites active={open} setActive={setOpen} requisite={requisite}/>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default EditProfile;