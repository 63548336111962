import { Navigate, Outlet } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../Redux/Hooks/hook";
import { isLoggedIn } from "../Redux/Store/auth/selector";
import { useEffect } from "react";
import { accountGetMainUserAction, accountGetServicesAction } from "../Redux/Saga/account/accountSagaActions";
import { getReloadStatus } from "../Redux/Store/mainUser/mainUserSelector";


const PrivateRoute = () => {
    const auth = useAppSelector(isLoggedIn);
    const dispatch = useAppDispatch();
    // Флаг для повторного запроса освновных данных
    const reloadStatus = useAppSelector(getReloadStatus);

    useEffect(() => {
        dispatch(accountGetMainUserAction());
        dispatch(accountGetServicesAction());
    }, [reloadStatus]);

    return (
        auth ? <Outlet /> : <Navigate to="/auth" />
    );
}
 
export default PrivateRoute;