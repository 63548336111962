import { ListContact } from "../../../Types/account/account.type";
import { DefaultsCompany, User } from "../../../Types/user.type";
import { RootState } from "../store";

export const GetUser = (state: RootState): string => {
    return state.user.tel_num;
};

export const getStep = (state: RootState): string => {
    return state.step.step;
};

export const getStatusAuth = (state: RootState): string => {
    return state.step.statusAuth;
};

export const getDefaultsCompany = (state: RootState): DefaultsCompany[] => {
    return state.user.defaults;
};

export const getAll = (state: RootState): User => {
    return state.user;
};

export const getListContacts = (state: RootState): ListContact[] => {
    return state.user.listContacts;
};

export const isLoggedIn = (state: RootState): boolean => {
    return state.isLoggedIn.entities;
};