import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type State = {
    timeZones: Array<Array<string>>
}

const initialState: State = {
    timeZones: []
};

const timeZoneSlice = createSlice({
    name: "timeZones",
    initialState,
    reducers: {
        timeZonesReceved: (state, action: PayloadAction<Array<Array<string>>>) => {
            state.timeZones = action.payload;
        }
    }
});


const { reducer: timeZonesReducer, actions } = timeZoneSlice;
export const { timeZonesReceved } = actions;

export default timeZonesReducer;