import { createAction } from "@reduxjs/toolkit";
import { DataChangeCompanyname, DataChangeInn, DataChangeTimeZone, DataChangeUserName, DataEditRequisite } from "./editProfile.types.saga";

export const accountChangeUserNameAction = 
    createAction<DataChangeUserName>("checkport/account/CHANGE_USER_NAME");

export const accountChangeCompanyNameAction =
    createAction<DataChangeCompanyname>("checkport/account/CHANGE_COMPANY_NAME");

export const accountChangeTimeZoneAction =
    createAction<DataChangeTimeZone>("checkport/account/CHANGE_TIME_ZONE");    

export const accountGetListTimeZonesAction =
    createAction("checkport/account/GET_LIST_TIME_ZONES");
    
export const accountChangeInnAction = 
    createAction<DataChangeInn>("checkport/account/CHANGE_INN");

export const accountGetInnUserAction =
    createAction("checkport/account/GET_INN_USER");

export const accountGetRequisiteAction =
    createAction("checkport/account/GET_REQUISITE");

export const accountEditRequisiteAction = 
    createAction<DataEditRequisite>("checkport/account/EDIT_REQUISITE");