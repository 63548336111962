import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListHistoryCalls } from "../../../Types/account/account.type";

type State = {
    historyCalls: ListHistoryCalls[],
    countCalls: number
}

const initialState: State = {
    historyCalls: [],
    countCalls: 1
};

const historyCallsSlice = createSlice({
    name: "historyCalls",
    initialState,
    reducers: {
        historyCallsReceved: (state, action: PayloadAction<ListHistoryCalls[]>) => {
            state.historyCalls = action.payload;
        },
        countCallsReceved: (state, action: PayloadAction<number>) => {
            state.countCalls = action.payload
        }
    }
});


const { reducer: historyCallsReducer, actions } = historyCallsSlice;
export const { historyCallsReceved, countCallsReceved } = actions;

export default historyCallsReducer;