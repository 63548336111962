import { ListActiveService, Requisite } from "../../../Types/account/account.type";
import { MainUser } from "../../../Types/user.type";
import { RootState } from "../store";

export const getActiveListServices = (state: RootState): ListActiveService[] => {
    return state.mainUser.entities.list_service;
};

export const getMainPage = (state: RootState): MainUser => {
    return state.mainUser.entities;
};

export const getRequisiteUser = (state: RootState): Requisite => {
    return state.requisiteUser.entities;
};


export const getBalance = (state: RootState): string => {
    return state.mainUser.entities.balance;
};

export const getActiveServiceById = (id: string | undefined) => (state: RootState): ListActiveService | null | undefined => {
    if (id) {
        return state.mainUser.entities.list_service.find((item) => item.id_service === id);
    } else {
        return null;
    };
};

export const getReloadStatus = (state: RootState): Boolean => {
    return state.mainUser.reload;
};