import { FC } from "react";
import styles from "./modalAddServiceInfo.module.scss";
import elipseGrey from "../../../../Assets/elipseGrey.svg";
import Modal from "../Modal/Modal";

type Props = {
    name: string,
    active: boolean,
    setActive: React.Dispatch<React.SetStateAction<boolean>>
};

const ModalAddServiceModal: FC<Props> = ({ name, active, setActive }) => {
    return (
        <Modal active={active} setActive={setActive}>
            <div className={styles.modal_content} onClick={(e) => e.stopPropagation()}>
                <h3>{name}</h3>
                <div className={styles.info}>
                    <div className={styles.text}>
                        <img className={styles.elipse} src={elipseGrey} alt="точка"/>
                        <p>до 5,000 авторизаций в месяц от 0,29 рубля в рамках тарифа</p>
                    </div>
                    <div className={styles.text}>
                        <img className={styles.elipse} src={elipseGrey} alt="точка"/>
                        <p>до 50,000 авторизаций в месяц от 0,25 рубля в рамках тарифа</p>
                    </div>
                    <div className={styles.text}>
                        <img className={styles.elipse} src={elipseGrey} alt="точка"/>
                        <p>до 500,000 авторизаций в месяц от 0,19 рубля в рамках тарифа</p>
                    </div>
                    <div className={styles.text}>
                        <img className={styles.elipse} src={elipseGrey} alt="точка"/>
                        <p>до 1,000,000 авторизаций в месяц от 0,15 рубля в рамках тарифа</p>
                    </div>
                </div>
                <button className={styles.button} onClick={() => setActive(false)}>Закрыть</button>
            </div>
        </Modal>
    );
};
 
export default ModalAddServiceModal;