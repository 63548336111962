import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useAppSelector } from '../../Redux/Hooks/hook';
import { getCountStatistic, getPriceStatistic } from '../../Redux/Store/statisticOutCall/statisticOutCallSelector';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
ChartJS.defaults.font.size = 18;
ChartJS.defaults.color = "#706F8E";
ChartJS.defaults.borderColor = "#706F8E";

const Chart = () => {
    const stats = useAppSelector(getPriceStatistic);
    const count = useAppSelector(getCountStatistic);

    if (!stats) {
        return(
            <div>
            ...Загрузка
            </div>
        )
    };
    
    const options = {
        plugins: {
            legend: {
                position: 'top' as const,
                labels: {
                    font: {
                        size: 25
                    }
                }
            },

        },
        scales: {
            x: {
                grid: {
                    display: false
                },
            },
            y: {
                type: 'linear' as const,
                display: true,
                position: 'left' as const,
                grid: {
                    display: false
                }
            },
                y1: {
                type: 'linear' as const,
                display: true,
                position: 'right' as const,
                grid: {
                    drawOnChartArea: false,
                },
            },
        },
    };
        
    const data = {
        labels: stats.map((item) => item.data),
        datasets: [
            {
                fill: true,
                label: 'Общая стоимость',
                data: stats.map((item) => item.price),
                borderColor: '#706F8E',
                backgroundColor: '#706F8E',
                yAxisID: "y"
            },
            {
                fill: true,
                label: 'Количество авторизаций',
                data: count.map((item) => item.count),
                borderColor: '#E9E9E9',
                backgroundColor: '#E9E9E9',
                yAxisID: "y1"
            },
            ],
        };
        return (
            <Bar options={options} data={data}/>
        );
};
 
export default Chart;
