import { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks/hook";
import { useNavigate } from "react-router-dom";
import { getMainPage } from "../../../Redux/Store/mainUser/mainUserSelector";
import Select from "react-select";
import ModalDeletePhone from "../../Ui/ModalWindows/ModalDeletePhone/ModalDeletePhone";
import TableHistoryBalance from "../../Ui/Table/TableHistoryBalance/TableHistoryBalance";
import { accountGetHistoryBalance, accountGetHistoryCalls, accountGetStatisticOutCallAction } from "../../../Redux/Saga/account/accountSagaActions";
import { getCountCalls, getListHistoryCalls } from "../../../Redux/Store/historyCalls/historyCallsSelector";
import TableHistoryCalls from "../../Ui/Table/TableHistoryCalls/TableHistoryCalls";
import { getListHistoryBalance, getMaxPage } from "../../../Redux/Store/historyBalance/historyBalanceSelector";
import transformPhoneToString from "../../../Utils/transformPhoneToString";
import { URL_INSTRUCTIONS_API, navigationListData, sortDateStats } from "../../../Constants/account.contstants";
import NavigationList from "../../Ui/Common/NavigationList/NavigationList";
import TableActiveServiceList from "../../Ui/Table/TableActiveServiceList/TableActiveServiceList";
import Chart from "../../Ui/Chart";
import HeaderPersonalAccount from "../../Layouts/HeaderPersonalAccount/HeaderPersonalAccount";
import deleteIcons from "../../../Assets/deleteIcons.svg";
import elipse from "../../../Assets/elipse.svg";
import styles from "./personalAccount.module.scss";

const PersonalAccount: FC = () => {
    const [open, setOpen] = useState(false);
    const [name, setName] = useState("");
    const [pageCalls, setPageCalls] = useState(1);
    const [pageBalance, setPageBalance] = useState<number>(1);
    const [sort, setSort] = useState<string | undefined>("");
    const [sortStatistic, setSortStatistic] = useState<string | undefined>("60");
    const [directionSort, setDirectionSort] = useState<string | undefined>("-");

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const mainUser = useAppSelector(getMainPage);
    // Фильтр дополнительных номеров
    const otherContacts = mainUser.list_contact.filter((item) => item.main === "0");

    const historyCallsList = useAppSelector(getListHistoryCalls);
    const maxCountCalls = useAppSelector(getCountCalls);
    const maxPageBalance = useAppSelector(getMaxPage);

    const historyBalanceList = useAppSelector(getListHistoryBalance);

    useEffect(() => {
        dispatch(accountGetHistoryCalls({
            page: String(pageCalls),
            sort: sort ? sort : "call_time",
            direction: directionSort ? directionSort : "-"
        }));
        dispatch(accountGetHistoryBalance(String(pageBalance)));
        dispatch(accountGetStatisticOutCallAction(sortStatistic ? Number(sortStatistic) : 60));
    }, [pageCalls, sort, pageBalance, directionSort, sortStatistic]);
 
    // Пагинация истории звонков
    const handleChangePageCalls = (event: React.ChangeEvent<unknown>, value: number) => {
        setPageCalls(value);
    };

    // Пагинация истории баланса
    const handleChangePageBalance = (event: React.ChangeEvent<unknown>, value: number) => {
        setPageBalance(value);
    };

    return (
        <>
            <HeaderPersonalAccount />
            <main className={styles.main}>
                <div className={styles.item}>
                    <NavigationList options={navigationListData}/>
                    <div className={styles.listPhone}>
                        <h3>Добро пожаловать в личный кабинет, {mainUser.name}!</h3>
                        <p>Основной номер: {transformPhoneToString(mainUser.tel_num) ? transformPhoneToString(mainUser.tel_num) : ""}</p>
                        {otherContacts.length > 0 && 
                            <div>
                                <h3 className={styles.otherPhone}>Дополнительные номера</h3>
                                <ul>
                                    {otherContacts.map((item) => (
                                        <li key={item.date_time_create}>
                                            <img src={elipse} alt="точка" className={styles.elipse}/>
                                                {transformPhoneToString(item.tel_num)}
                                            <img src={deleteIcons} alt="иконка удалить" className={styles.deleteIcons} onClick={() => {
                                                setName(item.tel_num)
                                                setOpen(true)
                                            }}/>
                                            {item.tel_num === name && <ModalDeletePhone phone={item.tel_num} active={open} setActive={setOpen}/>}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        }
                        <button className={styles.button} onClick={() => navigate("/add_phone")}>Добавить номер</button>
                    </div>
                </div>
                <div className={styles.card} id="services">
                    <h2>Услуги</h2>
                    {mainUser.list_service.length > 0
                        ? 
                            <TableActiveServiceList
                                data={mainUser.list_service}
                                name={name}
                                active={open}
                                setName={setName}
                                setActive={setOpen}
                            />
                        :
                            <p className={styles.info}>В данный момент нет подключенных услуг и тарифов</p>
                    }
                    <div className={styles.buttons_list}>
                        <button className={styles.button} onClick={() => navigate("/add_service")}>Подключить тариф</button>
                        <button className={styles.button} onClick={() => window.open(URL_INSTRUCTIONS_API, "_blank")}>Иструкция API</button>
                    </div>
                </div>
                <div className={styles.card} id="historyBalance">
                    <h2>История пополнения баланса кабинета</h2>
                    {historyBalanceList.length > 0
                        ? 
                            <TableHistoryBalance
                                data={historyBalanceList}
                                handleChange={handleChangePageBalance}
                                maxCount={maxPageBalance}
                                page={pageBalance}
                            />
                        :
                            <p className={styles.info}>В данный момент нет истории пополнения баланса</p> 
                    }
                </div>
                <div className={styles.card} id="historyCalls">
                    <h2>История звонков</h2>
                    {historyCallsList.length > 0
                        ?
                            <TableHistoryCalls
                                data={historyCallsList}
                                handleChange={handleChangePageCalls}
                                page={pageCalls}
                                maxCount={maxCountCalls}
                                setSort={setSort}
                                setDirectionSort={setDirectionSort}
                            />
                        :
                            <p className={styles.info}>В данный момент нет истории звонков</p>
                    }
                </div>
                <div className={styles.card}>
                    <h2>Статистика</h2>
                    <div className={styles.stats_sort}>
                        <Select
                            options={sortDateStats}
                            className="react-select-container-sort"
                            classNamePrefix="react-select-sort"
                            defaultValue={sortDateStats[0]}
                            isSearchable={false}
                            onChange={(item) => setSortStatistic(item?.value)} 
                        />
                    </div>
                    <div className={styles.stats}>
                        <Chart />
                    </div>
                </div>
            </main>
        </>
    );
};

export default PersonalAccount;