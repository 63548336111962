import { ListServices } from "../../../Types/account/account.type";
import { RootState } from "../store";

export const getListServices = (state: RootState): ListServices[] => {
    return state.services.services;
};

export const getServiceById = (id: string | undefined) => (state: RootState): ListServices | undefined => {
    if (id) {
        return state.services.services.find((item) => item.id_service === id);
    };
};