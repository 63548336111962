import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CountsStatisticOutCall, PriceStatisticOutCall } from "../../../Types/account/account.type";

type State = {
    price: PriceStatisticOutCall[],
    count: CountsStatisticOutCall[]
};

const initialState: State = {
    price: [],
    count: []
};

const statisticOutCallSlice = createSlice({
    name: "statisticOutCall",
    initialState,
    reducers: {
        priceOutCallReceved: (state, action: PayloadAction<PriceStatisticOutCall[]>) => {
            state.price = action.payload;
        },
        countOutCallReceved: (state, action: PayloadAction<CountsStatisticOutCall[]>) => {
            state.count = action.payload;
        }
    }
});


const { reducer: statisticOutCallReducer, actions } = statisticOutCallSlice;
export const { priceOutCallReceved, countOutCallReceved } = actions;

export default statisticOutCallReducer;