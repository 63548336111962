import { Navigate, Route, Routes } from 'react-router-dom';
import Authorization from './Components/Authorization';
import AddNewPhonePage from './Components/Pages/AddNewPhonePage/AddNewPhonePage';
import AddServicesPage from './Components/PagesAccount/AddServicePage/AddServicesPage';
import ConfirmAddServicePage from './Components/PagesAccount/ConfirmAddServicePage/ConfirmAddServicePage';
import PersonalAccount from './Components/Pages/PersonalAccount/PersonalAccount';
import AddBalance from './Components/PagesAccount/AddBalance/AddBalance';
import EditProfile from './Components/PagesAccount/EditProfile/EditProfile';
import PrivateRoute from './Components/PrivateRoute';
import EditTokenPage from './Components/PagesAccount/EditTokenPage/EditTokenPage';
import AlertError from './Components/Ui/AlertError';

function App() {

    return (
        <>
            <Routes>
                <Route path="/auth" element={<Authorization />} />
                <Route element={<PrivateRoute />}>
                    <Route path="/" element={<PersonalAccount />} />
                    <Route path='add_phone' element={<AddNewPhonePage />} />
                    <Route path="add_service" element={<AddServicesPage />} />
                    <Route path="confirm/:id" element={<ConfirmAddServicePage />} />
                    <Route path="add_balance" element={<AddBalance />} />
                    <Route path="edit_profile" element={<EditProfile />} />
                    <Route path="edit_token/:id" element={<EditTokenPage />} />
                </Route>
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <AlertError />
        </>
    );
};

export default App;
