import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Redux/Hooks/hook";
import { getActiveServiceById } from "../../../Redux/Store/mainUser/mainUserSelector";
import { accountCreateActiveServiceAction, accountUpdateActiveServiceAction } from "../../../Redux/Saga/account/activeServicesSaga/activeServicesActions";
import ModalConfirmDeleteActiveToken from "../../Ui/ModalWindows/ModalEditService/ModalConfirmDeteleActiveToken";
import HeaderOptions from "../../Layouts/HeaderOptions/HeaderOptions";
import deleteIcon from "../../../Assets/deleteIcons.svg";
import playIcon from "../../../Assets/playIcon.svg";
import stopIcon from "../../../Assets/stopIcon.svg";
import styles from "./editTokenPage.module.scss";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { errorsMessageAxiosReceved, stateErrorsMessageReceved } from "../../../Redux/Store/errorsMessage/errorsMessage";

type Data = {
    name_token: string
};

const EditTokenPage: FC = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [idToken, setIdToken] = useState<string>("");
    const [copied, setCopied] = useState(false);
    const { register, handleSubmit, formState: { errors }, reset } = useForm<Data>();
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const activeService = useAppSelector(getActiveServiceById(id));

    // При загрузке данных
    if (activeService === null) {
        return (
            <div>
                Загрузка...
            </div>
        );
    };

    // При удалении всех токенов
    if (activeService === undefined) {
        return <Navigate to="/personal_account"/>
    };

    // Обновление данных токена, в основном пауза и запуск токена
    const updateActiveService = (id_token: string, pause_token: "1" | "0", token_key: string, desc: string) => {
        dispatch(accountUpdateActiveServiceAction({
            id_token: id_token,
            desc: desc,
            pause_token: pause_token,
            token_key: token_key
        }));
    };    

    // Создание нового токена
    const createActiveService = (data: Data) => {
        dispatch(accountCreateActiveServiceAction({
            id_service: activeService.id_service,
            desc: data.name_token,
            token_key: ""
        }));
        reset();
    };

    return (
        <>
            <HeaderOptions stepOne="Личный кабинет" stepTwo={`${activeService.service_name} (токены)`} id={activeService.id_service}/>
            <div className={styles.main}>
                <h2>Название услуги</h2>
                <div>
                    <div className={styles.name}>Название токена: <p>{activeService.service_name}</p></div>
                    <p>Добавить имя для токена</p>
                    <form className={styles.form} onSubmit={handleSubmit(createActiveService)}>
                        <div className={styles.form_item}>
                            <input
                                type="text"
                                defaultValue={""}
                                className={errors.name_token ? styles.error_input : styles.input}
                                {...register("name_token", { required: "Поле обязательно для заполнения" })}
                            />
                            {errors.name_token && <p className={styles.error}>{errors.name_token.message}</p>}
                        </div>
                        <button className={styles.button} onClick={handleSubmit(createActiveService)}>Сгенерировать токен</button>
                    </form>
                </div>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th style={{ width: 10}}>№</th>
                            <th>Имя токена</th>
                            <th className={styles.item_header}>Хэширование токена</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {activeService.tokens.map((item, index) => (
                            <tr key={item.id_token}>
                                <td>{index + 1}</td>
                                <td>{item.desc}</td>
                                <td style={{ maxWidth: '100px'}}>
                                    <div className={styles.hash_token}>
                                        <CopyToClipboard text={item.token_key}
                                            onCopy={() => {
                                                setCopied(true);
                                                dispatch(errorsMessageAxiosReceved("Текст скопирован"));
                                                dispatch(stateErrorsMessageReceved({
                                                    open: true,
                                                    type: "success"
                                                }));
                                            }}>
                                            <button className={styles.button_copy}>Скопировать</button>
                                        </CopyToClipboard>
                                        <div className={styles.token_key}>{item.token_key}</div>
                                    </div>
                                </td>
                                <td className={styles.options}>
                                    {item.pause_token === "1" &&
                                        <img
                                            src={playIcon}
                                            alt="запустить"
                                            onClick={() => updateActiveService(item.id_token, "0", item.token_key, item.desc)}
                                        />
                                    }
                                    {item.pause_token === "0" &&
                                        <img
                                            src={stopIcon}
                                            alt="остановить"
                                            onClick={() => updateActiveService(item.id_token, "1", item.token_key, item.desc)}
                                        />
                                    }
                                    <img src={deleteIcon} alt="удалить" onClick={() => {setOpen(true); setIdToken(item.id_token)}}/>
                                    {idToken === item.id_token &&
                                        <ModalConfirmDeleteActiveToken
                                            id={item.id_token}
                                            active={open}
                                            setActive={setOpen}
                                            name={item.desc}
                                        />
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};
 
export default EditTokenPage;