import { RootState } from "../store";
import { ISetOpen } from "./errorsMessage";

export const getErrorsMessage = (state: RootState): string => {
    return state.errorsMessage.entities;
};

export const geteErrorsMessageAxios = (state: RootState): string => {
    return state.errorsMessage.errorMessageAxios;
};

export const getStateErrorMessage = (state: RootState): ISetOpen => {
    return state.errorsMessage.setOpen;
};