import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListHistoryBalance } from "../../../Types/account/account.type";

type State = {
    historyBalance: ListHistoryBalance[],
    maxPage: number,
    balance: string
}

const initialState: State = {
    historyBalance: [],
    maxPage: 1,
    balance: "0"
};

const historyBalanceSlice = createSlice({
    name: "historyBalance",
    initialState,
    reducers: {
        historyBalanceReceved: (state, action: PayloadAction<ListHistoryBalance[]>) => {
            state.historyBalance = action.payload;
        },
        maxPageReceved: (state, action: PayloadAction<number>) => {
            state.maxPage = action.payload
        },
        balanceReceved: (state,action: PayloadAction<string>) => {
            state.balance = action.payload
        }
    }
});


const { reducer: historyBalanceReducer, actions } = historyBalanceSlice;
export const { historyBalanceReceved, maxPageReceved, balanceReceved } = actions;

export default historyBalanceReducer;