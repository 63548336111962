const transformDate = (date: string): string => {
    const resultArray = [];
    const array = date.split(" ");
    const dateArray = array[0].split("-");
    for (let i=0; i < dateArray.length; i++) {
        resultArray.unshift(dateArray[i]);
    }
    const result = resultArray.join(".");
    const time = array[1].slice(0, 8);
    return `${result} | ${time}`;
};

export default transformDate;