import { createAction } from "@reduxjs/toolkit";
import { DataDeleteContact, DataGetHistoryCalls, DataPhone } from "./account.types.saga";

export const accountAppendTelNumAction =
    createAction<DataPhone>("checkport/account/APPEND_TEL_NUM");

export const accountDeleteContactAction = 
    createAction<DataDeleteContact>("checkport/account/DELETE_CONTACT");

export const accountGetServicesAction = 
    createAction("checkport/account/GET_SERVICES");

export const accountGetHistoryCalls =
    createAction<DataGetHistoryCalls>("checkport/account/GET_HISTORY_CALLS");

export const accountGetHistoryBalance =
    createAction<string>("checkport/account/GET_HISTORY_BALANCE");
    
export const accountGetMainUserAction = 
    createAction("checkport/account/GET_MAIN_USER");
  
export const accountAddBalanceAction = 
    createAction<string>("checkport/account/ADD_BALANCE");

export const accountGetStatisticOutCallAction = 
    createAction<number>("checkport/account/GET_STATISTIC_OUT_CALL");    
