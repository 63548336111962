import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListServices } from "../../../Types/account/account.type";

type State = {
    services: ListServices[]
}

const initialState: State = {
    services: []
};

const servicesSlice = createSlice({
    name: "services",
    initialState,
    reducers: {
        servicesReceved: (state, action: PayloadAction<ListServices[]>) => {
            state.services = action.payload;
        }
    }
});


const { reducer: servicesReducer, actions } = servicesSlice;
export const { servicesReceved } = actions;

export default servicesReducer;