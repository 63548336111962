import { IupdateActiveServiceCustomer } from "../../Types/account/account.type";
import { ChangeProfileResponse } from "../../Types/account/accountResponse.type";
import account, { BASE_END_POINT } from "../config";

const activeServicesService = {
    async deleteActiveService(id: string): Promise<ChangeProfileResponse> {
        const response = await account.post(BASE_END_POINT, {
            action: "delete_activ_service",
            id_service: id
        });
        return response.data;
    },
    async updateActiveServiceCustomer(data: IupdateActiveServiceCustomer): Promise<ChangeProfileResponse> {
        const response = await account.post(BASE_END_POINT, {
            action: "update_activ_service_customer",
            id_token: data.id_token,
            desc: data.desc,
            token_key: data.token_key,
            pause_token: data.pause_token
        });
        return response.data;
    },
    async createActiveService(id: string, desc: string, token_key: string): Promise<ChangeProfileResponse> {
        const response = await account.post(BASE_END_POINT, {
            action: "create_activ_service_customer",
            id_service: id,
            desc: desc,
            token_key: token_key
        });
        return response.data;
    },
    async deleteActiveToken(id_token: string): Promise<ChangeProfileResponse> {
        const response = await account.post(BASE_END_POINT, {
            action: "delete_activ_token",
            id_token: id_token
        });
        return response.data;
    },
    async pauseActiveService(id_service: string, pause_service: "0" | "1"): Promise<ChangeProfileResponse> {
        const response = await account.post(BASE_END_POINT, {
            action: "pause_activ_service",
            id_service: id_service,
            pause_service: pause_service
        });
        return response.data;
    }
};

export default activeServicesService;