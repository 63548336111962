import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Step = {
    step: string,
    statusAuth: string,
};

const initialState: Step = {
    step: "auth",
    statusAuth: ""
};

const stepSlice = createSlice({
    name: "step",
    initialState,
    reducers: {
        stepReceved: (state, action: PayloadAction<string>) => {
            state.step = action.payload;
        },
        statusAuthReceved: (state, action: PayloadAction<string>) => {
            state.statusAuth = action.payload
        }
    }
});


const { reducer: stepReducer, actions } = stepSlice;
export const { stepReceved, statusAuthReceved } = actions;

export default stepReducer;