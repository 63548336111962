import { FC } from 'react';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import { Alert, Fade } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks/hook';
import { stateErrorsMessageReceved } from '../../Redux/Store/errorsMessage/errorsMessage';
import { getStateErrorMessage, geteErrorsMessageAxios } from '../../Redux/Store/errorsMessage/errorsMessageSelector';
import { stat } from 'fs';

export interface State extends SnackbarOrigin {
  open: boolean;
};

const AlertError: FC = ({}) => {
    const dispatch = useAppDispatch();
    const state = useAppSelector(getStateErrorMessage);
    const errorMessage = useAppSelector(geteErrorsMessageAxios);

    const handleClose = () => {
        dispatch(stateErrorsMessageReceved({
            open: false,
            type: state.type
        }));
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={state.open}
            autoHideDuration={3000}
            onClose={handleClose}
            TransitionComponent={Fade}
        >
            <Alert onClose={handleClose} severity={state.type} sx={{ width: '100%', fontSize: 18 }}>
                {errorMessage}
            </Alert>
        </Snackbar>
    );
};

export default AlertError;