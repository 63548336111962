import { FC } from "react";
import { ListHistoryCalls } from "../../../../Types/account/account.type";
import { Pagination } from "@mui/material";
import Select from "react-select";
import transformDate from "../../../../Utils/transormDate";
import transformPhoneToString from "../../../../Utils/transformPhoneToString";
import { directionArrays, sortArrays } from "../../../../Constants/account.contstants";
import styles from "./tableHistoryCalls.module.scss";

type Props = {
    data: ListHistoryCalls[],
    page: number,
    maxCount: number,
    handleChange: (event: React.ChangeEvent<unknown>, value: number) => void,
    setSort: React.Dispatch<React.SetStateAction<string | undefined>>,
    setDirectionSort: React.Dispatch<React.SetStateAction<string | undefined>>
};

const TableHistoryCalls: FC<Props> = ({ data, handleChange, page, maxCount, setSort, setDirectionSort }) => {
    return (
        <div className={styles.container}>
            <div style={{ width: "100%"}}>
                <div className={styles.sort}>
                    <p>Сделать сортировку по: </p>
                    <Select
                        options={sortArrays}
                        className="react-select-container-sort"
                        classNamePrefix="react-select-sort"
                        defaultValue={sortArrays[0]}
                        isSearchable={false}
                        onChange={(item) => setSort(item?.value)} 
                    />
                    <Select
                        options={directionArrays}
                        className="react-select-container-sort"
                        classNamePrefix="react-select-sort"
                        defaultValue={directionArrays[0]}
                        isSearchable={false}
                        onChange={(item) => setDirectionSort(item?.value)} 
                    />
                </div>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>№</th>
                            <th>Дата и время звонка</th>
                            <th>Номер телефона, <br />с которого звонили</th>
                            <th>Оператор связи</th>
                            <th>Номер телефона, <br />на который звонили</th>
                            <th>Стоимость звонка</th>
                            <th>Тариф</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                <td>{item.number}</td>
                                <td>{transformDate(item.call_time)}</td>
                                <td>{transformPhoneToString(item.num_a)}</td>
                                <td>{item.operator}</td>
                                <td>{transformPhoneToString(item.num_b)}</td>
                                <td>{item.price} руб</td>
                                <td>{item.service_name}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Pagination page={page} count={maxCount} size="large" shape="rounded" onChange={handleChange}/>
        </div>
    );
}
 
export default TableHistoryCalls;