import { FC } from "react";
import { useAppDispatch } from "../../../../Redux/Hooks/hook";
import { accountDeleteActiveTokenAction } from "../../../../Redux/Saga/account/activeServicesSaga/activeServicesActions";
import Modal from "../Modal/Modal";
import styles from "./modalConfirmDeleteActiveToken.module.scss";


type Props = {
    name: string,
    id: string,
    active: boolean,
    setActive: React.Dispatch<React.SetStateAction<boolean>>
};


const ModalConfirmDeleteActiveToken: FC<Props> = ({ active, setActive, name, id }) => {
    const dispatch = useAppDispatch();

    const deleteActiveToken = (id: string) => {
        dispatch(accountDeleteActiveTokenAction({
            id_token: id,
            setActive: setActive
        }));
    };

    return (
        <Modal active={active} setActive={setActive}>
            <div className={styles.modal_content} onClick={(e) => e.stopPropagation()}>
                <h3>Удаление токена</h3>
                <p>Вы действительно хотите удалить токен «{name}»?</p>
                <div className={styles.buttons_list}>
                    <button className={styles.button} onClick={() => deleteActiveToken(id)}>Да</button>
                    <button className={styles.button} onClick={() => setActive(false)}>Нет</button>
                </div>
            </div>
        </Modal>
    );
};
 
export default ModalConfirmDeleteActiveToken;