import { all, fork } from "redux-saga/effects";
import authSaga from "./auth/authSaga";
import accountSaga from "./account/accountSaga";
import editProfileSaga from "./account/editProfileSaga/editProfileSaga";
import activeServicesSaga from "./account/activeServicesSaga/activeServicesSaga";

export default function* rootSaga() {
    yield all([
        fork(authSaga),
        fork(accountSaga),
        fork(editProfileSaga),
        fork(activeServicesSaga)
    ])
}