import { createAction } from "@reduxjs/toolkit";
import {
    DataCreateActiveService,
    DataDeleteActiveService,
    DataDeleteActiveToken,
    DataPauseActiveService
    } from "./activeServices.types.saga";
import { IupdateActiveServiceCustomer } from "../../../../Types/account/account.type";

export const accountDeleteActiveServiceAction =
    createAction<DataDeleteActiveService>("checkport/account/DELETE_ACTIVE_SERVICE");
    
export const accountUpdateActiveServiceAction =
    createAction<IupdateActiveServiceCustomer>("checkport/account/UPDATE_ACTIVE_SERVICE");

export const accountCreateActiveServiceAction = 
    createAction<DataCreateActiveService>("checkport/account/CREATE_ACTIVE_SERVICE");

export const accountDeleteActiveTokenAction =
    createAction<DataDeleteActiveToken>("checkport/account/DELETE_ACTIVE_TOKEN");

export const accountPauseActiveServiceAction =
    createAction<DataPauseActiveService>("checkport/account/PAUSE_ACTIVE_TOKEN");