import axios, { AxiosInstance } from "axios";
import { DataResponseCreateUser, DataResponseDigits, EndLoginPost, EndLoginResponse, LogOutResponse, Phone, PostCreateUser, ResponseLogin } from "../Types/auth/auth.type";
import { config } from "./config";

const auth: AxiosInstance = axios.create(config);

export const authServices = {
    async registration(phone: Phone): Promise<ResponseLogin> {
        try {
            const response = await auth.post("Register/register", phone);
            return response.data;
        } catch (error: any) {
            return error;
        };
    },
    async confirmation(digits: string, phone:string, login: string): Promise<DataResponseDigits> {
        try {
            const response = await auth.post("Register/check-digits", {
                tel_num: phone,
                digits: digits,
                login: login
            });
            return response.data;
        } catch (error: any) {
            return error;
        };
    },
    async createUser(data: PostCreateUser): Promise<DataResponseCreateUser> {
        try {
            const response = await auth.post("Register/end-register", data);
            return response.data;
        } catch (error: any) {
            return error;
        };
    },
    async login(phone: Phone): Promise<ResponseLogin> {
        const response = await auth.post("Register/login", phone);
        return response.data;
    },
    async endLogin(data: EndLoginPost): Promise<EndLoginResponse> {
        try {
            const response = await auth.post("Register/end-login", data);
            return response.data;
        } catch(error: any) {
            return error;
        }
    },
    async logOut(): Promise<LogOutResponse> {
        try {
            const response = await auth.post("Register/logout");
            return response.data
        } catch(error: any) {
            return error
        }
    }
}
