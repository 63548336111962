import { FC, useEffect } from "react";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "../../../../Redux/Hooks/hook";
import { Controller, useForm } from "react-hook-form";
import { GetTimezZonesDefaults } from "../../../../Redux/Store/timeZones/selectorsTimeZones";
import { accountChangeTimeZoneAction, accountGetListTimeZonesAction } from "../../../../Redux/Saga/account/editProfileSaga/editProfileSagaActions";
import transormArraysToObject from "../../../../Utils/transformArrays";
import Modal from "../Modal/Modal";
import styles from "./modalChangeTimeZone.module.scss";

type Props = {
    active: boolean,
    setActive: React.Dispatch<React.SetStateAction<boolean>>
};

type UserName = {
    time_zone: {
        value: string,
        label: string
    }
};

const ModalChangeTimeZone: FC<Props> = ({ active, setActive }) => {
    const { control, handleSubmit, formState: { errors } } = useForm<UserName>();
    const dispatch = useAppDispatch();
    const timeZones = useAppSelector(GetTimezZonesDefaults);
    const options = transormArraysToObject(timeZones);

    useEffect(() => {
        dispatch(accountGetListTimeZonesAction());
    }, []);
    
    const onSubmit = (data: UserName) => {
        dispatch(accountChangeTimeZoneAction({
            time_zone: data.time_zone.value,
            setActive: setActive
        }));
    };

    return (
        <Modal active={active} setActive={setActive}>
            <form className={styles.modal_content} onClick={(e) => e.stopPropagation()} onSubmit={handleSubmit(onSubmit)}>
                <h3>Смена часового пояса</h3>
                <Controller
                            control={control}
                            name="time_zone"
                            rules={{ required: "Выберите часовой пояс" }}
                            render={({
                                field: { value, onChange }
                            }) => (
                                <Select
                                    options={options}
                                    value={value}
                                    onChange={onChange}
                                    className="react-select-container"
                                    classNamePrefix={errors.time_zone ? "react-error" : "react-select"}
                                    isSearchable={false}
                                    placeholder={errors.time_zone ? "Выберите часовой пояс" : "Выбрать..." }
                                    loadingMessage={() => "Загрузка..."}
                                />
                            )} 
                        />
                <div className={styles.buttons_list}>
                    <button className={styles.button} onClick={handleSubmit(onSubmit)}>Изменить</button>
                    <button className={styles.button} onClick={() => setActive(false)}>Отмена</button>
                </div>
            </form>
        </Modal>
    );
};
 
export default ModalChangeTimeZone;