// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".navigationList_nav__yZCDO {\n  text-align: center;\n  margin-bottom: 60px;\n}\n.navigationList_nav__yZCDO a {\n  text-transform: uppercase;\n  margin: 0 40px;\n  cursor: pointer;\n}\n.navigationList_nav__yZCDO a:hover {\n  text-decoration: underline;\n}\n@media screen and (max-width: 1170px) {\n  .navigationList_nav__yZCDO {\n    display: flex;\n    flex-direction: column;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Components/Ui/Common/NavigationList/navigationList.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,mBAAA;AACJ;AACI;EACI,yBAAA;EACA,cAAA;EACA,eAAA;AACR;AACQ;EACI,0BAAA;AACZ;AAGI;EAdJ;IAeQ,aAAA;IACA,sBAAA;EAAN;AACF","sourcesContent":[".nav {\n    text-align: center;\n    margin-bottom: 60px;\n\n    a {\n        text-transform: uppercase;\n        margin: 0 40px;\n        cursor: pointer;\n\n        &:hover {\n            text-decoration: underline;\n        }\n    }\n\n    @media screen and (max-width: 1170px) {\n        display: flex;\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav": "navigationList_nav__yZCDO"
};
export default ___CSS_LOADER_EXPORT___;
