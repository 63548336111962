import axios from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "redux-saga/effects";
import editProfileServices from "../../../../Services/accountServices/editProfiles.service";
import { timeZonesReceved } from "../../../Store/timeZones/timeZones";
import { innUserReceved } from "../../../Store/innUser/innUser";
import { requisiteUserReceved } from "../../../Store/mainUser/requisite";
import { isLoggedInReceved } from "../../../Store/auth/isLoggedIn";
import { errorsMessageAxiosReceved, stateErrorsMessageReceved } from "../../../Store/errorsMessage/errorsMessage";
import {
    AccountChangeCompanyName,
    AccountChangeInn,
    AccountChangeTimeZone,
    AccountChangeUserName,
    AccountEditRequisite,
    AccountGetInnUser, 
    AccountGetListTimeZones,
    AccountGetRequisite,
    DataChangeCompanyname,
    DataChangeInn,
    DataChangeTimeZone,
    DataChangeUserName, 
    DataEditRequisite }
    from "./editProfile.types.saga";
import {
    accountChangeCompanyNameAction,
    accountChangeInnAction,
    accountChangeTimeZoneAction,
    accountChangeUserNameAction,
    accountEditRequisiteAction,
    accountGetInnUserAction,
    accountGetListTimeZonesAction,
    accountGetRequisiteAction
} from "./editProfileSagaActions";

function* changeUserNameSaga(data: PayloadAction<DataChangeUserName>) {
    try {
        const response: AccountChangeUserName = yield call(editProfileServices.changeUserName, data.payload.name);

        if (response.status === "200") {
            yield data.payload.setActive(false);
        };
        
        if (response.status === "401") {
            yield put(isLoggedInReceved(false));
            yield put(errorsMessageAxiosReceved("Необходимо авторизоваться"));
            yield put(stateErrorsMessageReceved({
                open: true,
                type: "error"
            }));
        };
    } catch(error) {
        if(axios.isAxiosError(error)) {
            if (error.response) {
                yield put(errorsMessageAxiosReceved("Проблемы с сервером, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else if(error.request) {
                yield put(errorsMessageAxiosReceved("Проблемы с соединением, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else {
            };
        };
    };
};

function* changeCompanyNameSaga(data: PayloadAction<DataChangeCompanyname>) {
    try {
        const response: AccountChangeCompanyName = yield call(editProfileServices.changeCompanyName, data.payload.name);

        if (response.status === "200") {
            yield data.payload.setActive(false);
        };

        if (response.status === "401") {
            yield put(isLoggedInReceved(false));
            yield put(errorsMessageAxiosReceved("Необходимо авторизоваться"));
            yield put(stateErrorsMessageReceved({
                open: true,
                type: "error"
            }));
        };
    } catch(error) {
        if(axios.isAxiosError(error)) {
            if (error.response) {
                yield put(errorsMessageAxiosReceved("Проблемы с сервером, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else if(error.request) {
                yield put(errorsMessageAxiosReceved("Проблемы с соединением, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else {
            };
        };
    };
};

function* changeTimeZoneSaga(data: PayloadAction<DataChangeTimeZone>) {
    try {
        const response: AccountChangeTimeZone = yield call(editProfileServices.changeTimeZone, data.payload.time_zone);

        if(response.status === "200") {
            yield data.payload.setActive(false);
        };

        if (response.status === "401") {
            yield put(isLoggedInReceved(false));
            yield put(errorsMessageAxiosReceved("Необходимо авторизоваться"));
            yield put(stateErrorsMessageReceved({
                open: true,
                type: "error"
            }));
        };
    } catch(error) {
        if(axios.isAxiosError(error)) {
            if (error.response) {
                yield put(errorsMessageAxiosReceved("Проблемы с сервером, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else if(error.request) {
                yield put(errorsMessageAxiosReceved("Проблемы с соединением, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else {
            };
        };
    };
};

function* getListTimeZonesSaga() {
    try {
        const response: AccountGetListTimeZones = yield call(editProfileServices.getListTimeZones);

        if (response.status === "200") {
            yield put(timeZonesReceved(response.data));
        };

        if (response.status === "401") {
            yield put(isLoggedInReceved(false));
            yield put(errorsMessageAxiosReceved("Необходимо авторизоваться"));
            yield put(stateErrorsMessageReceved({
                open: true,
                type: "error"
            }));
        };
    } catch(error) {
        if(axios.isAxiosError(error)) {
            if (error.response) {
                yield put(errorsMessageAxiosReceved("Проблемы с сервером, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else if(error.request) {
                yield put(errorsMessageAxiosReceved("Проблемы с соединением, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else {
            };
        };
    };
};

function* changeInnSaga(data: PayloadAction<DataChangeInn>) {
    try {
        const response: AccountChangeInn = yield call(editProfileServices.changeInn, data.payload.inn);

        if (response.status === "200") {
            yield data.payload.setActive(false);
        };

        if (response.status === "401") {
            yield put(isLoggedInReceved(false));
            yield put(errorsMessageAxiosReceved("Необходимо авторизоваться"));
            yield put(stateErrorsMessageReceved({
                open: true,
                type: "error"
            }));
        };
    } catch(error) {
        if(axios.isAxiosError(error)) {
            if (error.response) {
                yield put(errorsMessageAxiosReceved("Проблемы с сервером, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else if(error.request) {
                yield put(errorsMessageAxiosReceved("Проблемы с соединением, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else {
            };
        };
    };
};

function* getInnUserSaga() {
    try {
        const response: AccountGetInnUser = yield call(editProfileServices.getInnUser);

        if (response.status === "200") {
            yield put(innUserReceved(response.data.inn));
        };

        if (response.status === "401") {
            yield put(isLoggedInReceved(false));
            yield put(errorsMessageAxiosReceved("Необходимо авторизоваться"));
            yield put(stateErrorsMessageReceved({
                open: true,
                type: "error"
            }));
        };
    } catch(error) {
        if(axios.isAxiosError(error)) {
            if (error.response) {
                yield put(errorsMessageAxiosReceved("Проблемы с сервером, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else if(error.request) {
                yield put(errorsMessageAxiosReceved("Проблемы с соединением, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else {
            };
        };
    };
};

function* getRequisiteSaga() {
    try {
        const response: AccountGetRequisite = yield call(editProfileServices.getRequisite);

        if (response.status === "200") {
            yield put(requisiteUserReceved(response.data.requisites[0]));
        };

        if (response.status === "401") {
            yield put(isLoggedInReceved(false));
            yield put(errorsMessageAxiosReceved("Необходимо авторизоваться"));
            yield put(stateErrorsMessageReceved({
                open: true,
                type: "error"
            }));
        };
    } catch(error) {
        if(axios.isAxiosError(error)) {
            if (error.response) {
                yield put(errorsMessageAxiosReceved("Проблемы с сервером, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else if(error.request) {
                yield put(errorsMessageAxiosReceved("Проблемы с соединением, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else {
            };
        };
    };
};

function* editRequisiteSaga(data: PayloadAction<DataEditRequisite>) {
    try {
        const response: AccountEditRequisite = yield call(editProfileServices.editRequisite, data.payload.requisite);

        if (response.status === "200") {
            yield data.payload.setActive(false);
        };

        if (response.status === "401") {
            yield put(isLoggedInReceved(false));
            yield put(errorsMessageAxiosReceved("Необходимо авторизоваться"));
            yield put(stateErrorsMessageReceved({
                open: true,
                type: "error"
            }));
        };
    } catch(error) {
        if(axios.isAxiosError(error)) {
            if (error.response) {
                yield put(errorsMessageAxiosReceved("Проблемы с сервером, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else if(error.request) {
                yield put(errorsMessageAxiosReceved("Проблемы с соединением, повторите позже"));
                yield put(stateErrorsMessageReceved({
                    open: true,
                    type: "error"
                }));
            } else {
            };
        };
    };
};

function* editProfileSaga() {
    yield all([
        takeLatest(accountChangeUserNameAction, changeUserNameSaga),
        takeLatest(accountChangeCompanyNameAction, changeCompanyNameSaga),
        takeLatest(accountChangeTimeZoneAction, changeTimeZoneSaga),
        takeLatest(accountGetListTimeZonesAction, getListTimeZonesSaga),
        takeLatest(accountChangeInnAction, changeInnSaga),
        takeLatest(accountGetInnUserAction, getInnUserSaga),
        takeLatest(accountGetRequisiteAction, getRequisiteSaga),
        takeLatest(accountEditRequisiteAction, editRequisiteSaga),
    ])
};

export default editProfileSaga;